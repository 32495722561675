import {Link} from "react-router-dom";

export const NoSourceState = () => {
    return (
        <div className={'shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg divide-y divide-gray-200 bg-white w-full mt-4'}>
            <div className={'flex flex-col w-1/2 mx-auto py-10'}>
                <p className="px-6  text-gray-400 text-center font-semibold ">
                    No Current Data Sources
                </p>
                <Link to={'/sources'} className={'px-6 py-3 text-white bg-ssg hover:bg-ssg-600 rounded-lg w-1/4 mx-auto mt-4'}>
                        Add Source +
                </Link>
            </div>
        </div>
    )
}
