import {Fragment, useContext, useEffect, useState} from "react";
import {FaTimes} from "react-icons/fa";
import {NumericGauge} from "../../AnalyticsAndStats/NumericGauge";
import {VisualizationContext} from "../../../context/VisualizationContext";

const baseStats = [
    {
        name: 'min',
        def: 'The lowest value in a given data set.'
    },
    {
        name: 'max',
        def: 'The highest value in a given data set.'
    },
    {
        name: 'mean',
        def: 'The average of all values in a data set.'
    },
    {
        name: 'median',
        def: 'The middle number in a data set.'
    },
    {
        name: 'mode',
        def: 'The number in a data set that occurs most often.'
    }
]
export const BaseStatsGrid = () => {
    const {
        hidden,
        setHidden,
        stats,
        xAxis,
        generalStat
    } = useContext(VisualizationContext);

    const [statOf, setStatOf] = useState('')
    useEffect(() => {
        if(generalStat && generalStat !== xAxis) {
            setStatOf(generalStat)
        } else {
            setStatOf(xAxis)
        }
    }, [generalStat, xAxis]);

    return (
        <Fragment>
            {
                stats ? baseStats.map(stat => {
                    return (
                        <Fragment key={stat.name}>
                            {hidden.includes(stat.name) ? null :
                                <div className={'col-span-1 sm:col-span-3 lg:col-span-3 bg-white rounded-lg p-3'}>
                                    <div className={'flex flex-row items-center justify-between'}>
                                        <label className={'font-bold capitalize'}>{stat.name}</label>
                                        <FaTimes onClick={_e => setHidden([...hidden, stat.name])} className={'cursor-pointer'}/>
                                    </div>
                                    <NumericGauge numericValue={stats[stat.name.toLowerCase()] ?? null} label={`${stat.name} ${statOf}`} max={stats.max} info={stat.def}/>
                                </div>}
                        </Fragment>
                    )
                }) : null
            }
        </Fragment>
    )
}
