import {useContext} from "react";
import {useUploadAction} from "../../../hooks/useUploadAction";
import {ComingSoon} from "../../Badges/ComingSoon";
import {Link} from "react-router-dom";
import {MenuContext} from "../../../context/MenuContext";
import {MapBuilderContext} from "../../../context/MapBuilderContext";

export const MapActionButton = ({styles, fileId}) => {
    const {setMenuOpen} = useContext(MenuContext);
    const {map, setMap} = useContext(MapBuilderContext);
    const action = useUploadAction('map');

    if (!action) return null;

    const redirectToMapSource = () => {
        setMap({
            source: {},
            mutation: {},
            searchable: undefined
        })

        window.location.href = `/sources/${fileId}/map`;
    }
    return (
        <button
            onClick={_e => redirectToMapSource()}
            className={styles
                ? styles
                : "rounded-md bg-gray-200 py-4 text-xs md:text-sm font-semibold text-ssg-800 shadow-sm hover:bg-ssg-500 hover:text-white capitalize relative w-full"}>{action.name}
            <ComingSoon available={action.available} />
        </button>
    )
}
