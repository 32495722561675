import {MdDataObject} from "react-icons/md";

export const MutationEmptyState = () => {
    return (
        <div
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
            <MdDataObject className={"text-gray-400 mx-auto h-12 w-12"}/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Click A Sample Property To Start</span>
        </div>
    )
}
