import React, {Fragment, useContext, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {CheckIcon, ChevronDownIcon, PlusIcon} from '@heroicons/react/20/solid'
import {useParams} from "react-router-dom";
import {MapBuilderContext} from "../../context/MapBuilderContext";
import {AlertContext} from "../../context/AlertContext";
import {AuthContext} from "../../context/AuthContext";
import axios from "axios";
import {ConfirmationOverlay} from "../Overlays/ConfirmationOverlay";
import {GeneralOverlay} from "../Overlays/GeneralOverlay";

export const  MapActionsMenu = () => {
    const routeParams = useParams();
    const fileId = routeParams.fileId;
    const {map, setMap, isLoading, setIsLoading} = useContext(MapBuilderContext);
    const {alert, setAlert} = useContext(AlertContext);
    const {authenticated} = useContext(AuthContext);
    const [confirmDownload, setConfirmDownload] = useState(false);
    const [mapDetails, setMapDetails] = useState(false);
    const saveMap = async () => {
        setIsLoading(true);

        if(!map.mutation || Object.keys(map.mutation).length === 0) {
            setAlert({...alert, status: 400, message: 'Cannot store a null map.'})
            setIsLoading(false);
            return;
        }

        try {
            const res = await axios.post(`/api/v1/datamaps/${fileId}`, {
                map: map.mutation,
                name: map.name,
                description: map.description
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setAlert({...alert, status: res.status, message: res.data.message})
            setMap({...map, mutation: {}, name: '', description: ''})
        } catch (error) {
            setAlert({...alert, status: error.status, message: error.response.data.message})
        }

        setIsLoading(false)
    }

    const download = async () => {
        setIsLoading(true)
        // hit api
        try {
            const res = await axios.post(`/api/v1/datamaps/${fileId}/download`, {
                map: map.mutation,
                name: map.name,
                description: map.description
            }, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            initiateBrowserDownload(res)
            setAlert({...alert, status: res.status, message: 'Downloading file'})
            setMap({...map, mutation: {}, name: '', description: ''})
        } catch (error) {
            setAlert({...alert, status: error.status, message: error.response.data.message})
        }

        setConfirmDownload(false)
        setIsLoading(false)
    }

    /**
     * Initiate browser download
     *
     * @param {AxiosResponse} res
     * @return {void}
     */
    const initiateBrowserDownload =  (res) => {
        const filename = getDownloadFilename(res)
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);

        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    /**
     * Get file name from response headers
     *
     * @param {object} res
     * @return {string}
     */
    const getDownloadFilename = (res) => {
        const contentDisposition = res.headers['content-disposition'];
        let filename = 'downloaded_file'; // default filename if not provided
        if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    }

    const generateNewSource = async () => {
        setIsLoading(true)
        // hit api
        try {
            const res = await axios.post(`/api/v1/datamaps/${fileId}/source`, {
                map: map.mutation,
                name: map.name,
                description: map.description
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setAlert({...alert, status: res.status, message: res.data.message})
            setMap({...map, mutation: {}, name: '', description: ''})
        } catch (error) {
            setAlert({...alert, status: error.status, message: error.response.data.message})
        }

        setConfirmDownload(false)
        setIsLoading(false)
    }

    return (
        <div className={"flex flex-row gap-4 items-center"}>
            <div className={"my-3"}>
                <button onClick={_e => setMapDetails(!mapDetails)} className={"flex flex-row items-center gap-2 rounded-full border border-ssg bg-ssg text-white duration-200 pr-4"}>
                    <div
                        className="rounded-full bg-ssg-500 p-1 text-white shadow-sm hover:bg-ssg-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <span className={"font-light text-sm"}>
                                   Map Details
                        </span>

                </button>
            </div>
            <div className="inline-flex rounded-md shadow-sm">
                <button
                    type="button"
                    onClick={e => saveMap()}
                    className="relative hover:bg-ssg hover:text-white inline-flex w-full items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"
                    disabled={isLoading}
                >
                    {isLoading ? 'Saving' : 'Save Map'}
                </button>
                <Menu as="div" className="relative -ml-px block">
                    <Menu.Button className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                        <span className="sr-only">Open options</span>
                        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-2">
                            <div className="py-1">
                                <Menu.Item>
                                    <button onClick={_e => setConfirmDownload(true)} className={"block px-4 py-2 text-sm hover:bg-gray-100 w-full rounded-lg text-left"}>
                                        Download
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button onClick={_e => generateNewSource()} className={"block px-4 py-2 text-sm hover:bg-gray-100 w-full rounded-lg text-left"}>
                                        Generate New Source
                                    </button>
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>

                { confirmDownload ?
                    <ConfirmationOverlay
                        open={confirmDownload}
                        setOpen={setConfirmDownload}
                        options={{
                            title: 'Confirm Download',
                            text: 'By clicking confirm you will begin the process of mapping the current file to the map just being built. You will also store the current map for reuse later.',
                            confirm: download,
                            cancel: () => setConfirmDownload(false)
                        }}
                    /> :
                    null}

                {mapDetails ?

                    <GeneralOverlay open={mapDetails} setOpen={setMapDetails}>


                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Enter Map Details
                                        </Dialog.Title>

                                    </div>
                                </div>
                                <div className={"flex flex-col gap-4"}>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Map Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="map.name"
                                                id="map.name"
                                                onChange={e => setMap({...map, name: e.target.value})}
                                                defaultValue={map.name}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="Enter Map Name"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                                            Map Description
                                        </label>
                                        <div className="mt-2">
                                    <textarea
                                        rows={4}
                                        name="map.description"
                                        onChange={e => setMap({...map, description: e.target.value})}
                                        id="map.description"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        defaultValue={map.description}
                                        placeholder="Describe the map"
                                    />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-ssg-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ssg-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ssg-500"
                                            onClick={() => setMapDetails(false)}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                </GeneralOverlay> : null}
            </div>
        </div>

    )
}
