import {LuAxis3D} from "react-icons/lu";
import {useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";

export const SelectXAxis = () => {
    const {
        setShowControls
    } = useContext(VisualizationContext);

    return (
        <button
            onClick={() => setShowControls(true)}
            className="col-span-full relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
            <LuAxis3D className={'m-auto text-7xl text-gray-600'}/>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Select An X-Axis To Render Chart</span>
        </button>
    )
}
