import { useContext, useEffect} from "react";
import { Chart } from 'react-chartjs-2';
import {VisualizationControlPanel} from "./VisualizationControlPanel";
import {MenuContext} from "../../context/MenuContext";
import {VisualizationContext} from "../../context/VisualizationContext";
import {StatsContainer} from "./Stats/StatsContainer";
import {SelectXAxis} from "./Popups/SelectXAxis";


export const VisualizeFile = () => {
    const {
        showControls,
        setShowControls,
        chart,
        file,
        xAxis
    } = useContext(VisualizationContext);

    const {setMenuOpen} = useContext(MenuContext)

    useEffect(() => {
        setMenuOpen(false)
    }, []);

    if(!chart && !file) return;

    return (
            <div className={'p-4 lg:p-8 grid grid-cols-1 sm:grid-cols-12 gap-4 col-span-full'}>
                <div className={'bg-white px-8 py-4 rounded-lg col-span-full flex flex-row items-center justify-between'}>
                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">Visualize</h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{file?.filename}{file?.metadata.extension ? `.${file?.metadata.extension}` : null}</p>
                        <p className={'text-xs bg-gray-100 p-3 rounded-lg italic'}><strong>Created At: </strong>{file.createdAt}</p>
                    </div>
                    <button
                        className={'bg-gray-100 p-3 rounded-lg text-sm font-medium cursor-pointer'}
                        onClick={Ee => setShowControls(true)}>
                        {!showControls ? 'Open Controls' : 'Close Controls'}
                    </button>
                </div>

                <StatsContainer />
                {!xAxis ? <SelectXAxis /> : null}
                {chart && xAxis ?
                    <Chart
                    type={chart.type}
                    data={{
                        labels: chart.data.labels,
                        datasets:  chart.data.datasets
                    }}
                    options={chart.options} /> :
                    null
                }


                <VisualizationControlPanel />
            </div>
    )
}

