import React, { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Login } from './Login'
import { Register } from './Register'

export const Guest = () => {
  return (
    <Fragment>
        <Routes>
            <Route path="/" element={<Login />}/>
            <Route path="/login" element={<Login />}/>
            <Route path="/register" element={<Register />}/>
        </Routes>
    </Fragment>
  )
}
