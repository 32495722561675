import axios from "axios";
import { getCookie } from "./getCookie";

export const useLogout = async () => {
    const token = getCookie('auth')
    // Make POST to /api/v1/auth/logout with token and invalidate token then

    const res = await axios.post('/api/v1/auth/logout', {}, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    })

    if(res.status === 204) {
        document.cookie = 'auth=false; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
        window.location.href = '/'
    }
}
