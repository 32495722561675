import {FaSpinner} from "react-icons/fa";
import {Fragment, useContext, useState} from "react";
import {APPROVED_FILE_TYPES, FILE_CONV_STATUSES} from "../../../staticData/static";
import axios from "axios";
import {AuthContext} from "../../../context/AuthContext";
import {AlertContext} from "../../../context/AlertContext";

/**
 * Conditionally render css class
 */
function classNames(...classes) {
    return classes
        .filter(Boolean)
        .join(' ');
}

export const FileNameInput = ({editUpload, setEditUpload, file, setRefreshTrigger}) => {
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false)

    /**
     * Update a single file
     *
     * @sets editUpload
     * @sets actingOn
     * @sets uploads
     * @sets alert
     * @return {void}
     */
    const updateFile = async () => {
        setLoading(!loading)
        try {
            const res = await axios.put(`/api/v1/files/${editUpload._id}/name`, editUpload, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })


            setEditUpload(undefined)
            setAlert({...alert, status: res.status, message: res.data.message})
            setRefreshTrigger(prev => prev + 1);
            setLoading(!loading)
        } catch (error) {
            setAlert({...alert, status: error.status, message: error.response.data.message})
            setLoading(!loading)
        }
    }

    /**
     * Get icon based on ext name
     *
     * @param {number} index
     * @return {Element}
     */
    const getExtensionIcon = (index) => {
        if(!index) return ;
        const IconComponent = APPROVED_FILE_TYPES[index]?.icon ?? APPROVED_FILE_TYPES['txt'].icon;
        return <>{IconComponent && <IconComponent className={"text-2xl text-gray-800"} />}</>
    }

    const getStatusType = (meta) => {
        if(meta.conversion === true) {
            return FILE_CONV_STATUSES['conversion']
        } else if (meta.mapped === true) {
            return FILE_CONV_STATUSES['mapped']
        } else {
            return FILE_CONV_STATUSES['original']
        }
    }

    const getStatusText = (meta) => {
        if(meta.conversion === true) {
            return 'Conversion'
        } else if (meta.mapped === true) {
            return 'Mapped'
        } else {
            return 'Original'
        }
    }


    return (
        <Fragment>
            {
                editUpload?._id === file._id.toString() ?
                    <div className='flex flex-row gap-4 items-center w-full sm:w-1/2'>
                        <input
                            type="text"
                            name="filename"
                            id={file.filename}
                            onChange={e => setEditUpload({...editUpload, [e.target.name]: e.target.value})}
                            onKeyPress={e => {
                                if (e.key === 'Enter') {
                                    updateFile()
                                }
                            }}
                            defaultValue={file.filename}
                            className={'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6'} />

                        <button onClick={updateFile} className='block rounded-md border-0 py-1.5 px-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6 hover:bg-gray-100' disabled={loading}>{loading ? <FaSpinner className='spin' /> : 'Save'}</button>
                    </div>
                    : <Fragment>
                        <p
                            onClick={_e => setEditUpload({...editUpload, _id: file._id})}
                            className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:bg-gray-100 px-3 rounded">{file.filename}</p>
                        <p
                            className={classNames(getStatusType(file.metadata), 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-1 text-xs font-medium ring-1 ring-inset capitalize')}>
                            {getStatusText(file.metadata)}
                        </p>
                        <p>
                            {getExtensionIcon(file.metadata.extension)}
                        </p>
                    </Fragment>}
        </Fragment>
    )
}
