import {useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";
import {ControlLabel} from "./ControlLabel";
import {ControlDetail} from "./ControlDetail";
import {Switch} from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const GeneralStatControl = () => {
    const {
        xAxis,
        generalStat,
        setGeneralStat,
        selections,
        calculateStatsFromSampleSize,
        setCalculateStatsFromSampleSize
    } = useContext(VisualizationContext);

    return (
        <div className={'bg-gray-100 p-3 rounded-lg'}>
            <div>
                <ControlLabel label={'Summary Stat Property'}/>
                <ControlDetail detail={'By default the summary statistics are calculated on the X-axis property if it is numeric.'} />
                <div className={'flex flex-col gap-2 my-3'}>

                    <select onChange={e => setGeneralStat(e.target.value)} className={'rounded-lg w-full capitalize'} defaultValue={generalStat ?? xAxis}>
                        {xAxis || generalStat ? <option value={generalStat ?? xAxis}>{generalStat ?? xAxis}</option> : <option>Select An Option</option>}
                        {Object.entries(selections).map(([key, value]) => {
                            if(key === generalStat ?? xAxis) return;

                            return (
                                <option key={key} value={key}>{key}</option>
                            )
                        })}
                    </select>
                </div>
            </div>

            <div>
                <div className={'flex flex-row items-center justify-between'}>
                    <ControlLabel label={'Calculate summary stats from sample size?'}/>
                    <div className={'flex flex-col gap-2 my-3'}>
                        <Switch
                            checked={calculateStatsFromSampleSize}
                            onChange={setCalculateStatsFromSampleSize}
                            className={classNames(
                                calculateStatsFromSampleSize ? 'bg-ssg-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-ssg-600 focus:ring-offset-2'
                            )}
                        >
                            <span className="sr-only">Use setting</span>
                            <span
                                className={classNames(
                                    calculateStatsFromSampleSize ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                            >
                        <span
                            className={classNames(
                                calculateStatsFromSampleSize ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                        >
                          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                            <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span
                            className={classNames(
                                calculateStatsFromSampleSize ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                            )}
                            aria-hidden="true"
                        >
                          <svg className="h-3 w-3 text-ssg-600" fill="currentColor" viewBox="0 0 12 12">
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                          </svg>
                        </span>
                      </span>
                        </Switch>
                    </div>
                </div>
                <div>
                    <ControlDetail detail={'By default the summary statistics are calculated on the sample size count. Disabling this enables statistical analysis on the full data set but can hinder performance.'} />
                </div>
            </div>

        </div>
    )
}
