import {useContext} from "react";
import {VisualizationContext} from "../../context/VisualizationContext";
import {Link, useParams} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

export const VisualizationStepButtons = ({nextable}) => {
    const {authenticated} = useContext(AuthContext)
    const params = useParams();
    const {step, setStep, reset, getChartData, setReady} = useContext(VisualizationContext);

    const handleSubmission = () => {
        getChartData(authenticated, params.fileId)
        setReady(true)
        setStep(prevStep => prevStep + 1)
    }
    return (
        <div className={'w-full flex flex-row items-center justify-between mt-6'}>
            <div >
                <Link to={'/visualize'}
                    onClick={() => reset()}>
                    <button type={'button'} className={'relative bg-white bg-opacity-50 px-6 py-3 rounded-lg text-gray-600 hover:bg-opacity-100 cursor-pointer'}>Close</button>
                </Link>
            </div>
            <div className={'flex flex-row gap-3 items-center'}>
                {
                    step > 0 ?
                        <button type="button" onClick={() => setStep(prevStep => prevStep - 1)} className={'relative bg-ssg px-6 py-3 rounded-lg text-white hover:bg-ssg-600 cursor-pointer'}>Previous</button>
                        : null
                }
                {
                    nextable ?
                        <button type="button" onClick={() => handleSubmission()} className={'relative bg-ssg px-6 py-3 rounded-lg text-white hover:bg-ssg-600 cursor-pointer'}>Next</button>
                        : null
                }
            </div>
        </div>
    )
}
