import {createContext, useContext, useEffect, useState} from "react";
import {LiaChartAreaSolid} from "react-icons/lia";
import {BsBarChart} from "react-icons/bs";
import {TbChartBubble} from "react-icons/tb";
import {BiDoughnutChart, BiLineChart, BiPieChartAlt2, BiRadar} from "react-icons/bi";
import {PiChartPolarThin, PiChartScatterThin} from "react-icons/pi";
import axios from "axios";
import {AlertContext} from "./AlertContext";

const chartTypes = [
    {
        type: 'area',
        Icon: LiaChartAreaSolid,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: false
    },
    {
        type: 'bar',
        Icon: BsBarChart,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: true
    },
    {
        type: 'bubble',
        Icon: TbChartBubble,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: true
    },
    {
        type: 'doughnut',
        Icon: BiDoughnutChart,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: false
    },
    {
        type: 'line',
        Icon: BiLineChart,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: true
    },
    {
        type: 'pie',
        Icon: BiPieChartAlt2,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: true
    },
    {
        type: 'polararea',
        Icon: PiChartPolarThin,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: false
    },
    {
        type: 'radar',
        Icon: BiRadar,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: true
    },
    {
        type: 'scatter',
        Icon: PiChartScatterThin,
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.',
        isAvailable: true
    }
]
const defaultChartType = 'bar'
export const VisualizationContext = createContext();

// API enpoints
const sampleEndPoint = '/api/v1/files/:id/sample'
const getFileEndPoint = '/api/v1/files/:id'

export const VisualizationProvider = ({children}) => {
    const {alert, setAlert} = useContext(AlertContext);
    const [token, setToken] = useState(undefined)
    const [fileId, setFileId] = useState(undefined)
    const [hidden, setHidden] = useState(['Complexity'])
    const [showControls, setShowControls] = useState(true)
    const [keys, setKeys] = useState([]);
    const [xAxis, setXAxis] = useState(undefined)
    const [chartType, setChartType] = useState(undefined)
    const [availableCharts, setAvailableCharts] = useState([])
    const [step, setStep] = useState(0);
    const [showChartSelection, setShowChartSelection] = useState(true)
    const [file, setFile] = useState(undefined)
    const [chart, setChart] = useState(undefined)
    const [sample, setSample] = useState(undefined)
    const [selections, setSelections] = useState({})
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [sampleSize, setSampleSize] = useState(100);
    const [stats, setStats] = useState(undefined)
    const [generalStat, setGeneralStat] = useState(undefined)
    const [calculateStatsFromSampleSize, setCalculateStatsFromSampleSize] = useState(true)
    /**
     * Get chart data based on user input
     */
    const getChartData = async (authToken, file_id) => {
        authToken = authToken ?? token;
        file_id = file_id ?? fileId;

        if(!authToken || !file_id) return;

        setLoading(true)

        try {
            // Construct the base URL
            let url = `/api/v1/visualize/cjs/${chartType ? chartType : defaultChartType }/${file_id}`;

            // Create an array to hold query parameters
            const queryParams = [];

            // If xAxis and yAxis are not undefined, push them to the queryParams array
            if (xAxis) {
                queryParams.push(`xAxisKey=${xAxis}`);
            }

            // Join the query parameters with "&" and append them to the base URL
            if (queryParams.length) {
                url += `?${queryParams.join('&')}`;
            }

            const res = await axios.post(url, {
                selections,
                sampleSize,
                generalStat,
                calculateStatsFromSampleSize
            },{
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`
                }
            });

            setKeys(res.data.availableKeys)
            setChart(res.data.chart)
            setStats(res.data.stats)
            console.log(res.data)

            setLoading(false)
        } catch (error) {
            const message = error.response.data.message ?? 'Something went wrong and cannot get chart data.'
            setAlert({...alert, stats: error.status, message})
        }
    }

    useEffect(() => {
        const sortChartsByAvailability = () => {
            const availableChartTypes = chartTypes.sort((a, b) => b.isAvailable - a.isAvailable)
            setAvailableCharts(availableChartTypes);
        }

        sortChartsByAvailability();

        if(ready) {
            getChartData();
        }
    }, [selections, xAxis, chartType, sampleSize, generalStat])

    /**
     * Initialize the chart process by fetching the file details & sample.
     * @param {string} token
     * @param {string} fileId
     *
     * @return {Promise<void>}
     */
    const init = async (token, fileId) => {
        if(!token || !fileId) return;
        setToken(token)
        setFileId(fileId)

        const file = await getFile(token, fileId);
        const sample = await getFileSample(token, fileId);

        setSample(sample)
        setFile(file)
    }

    /**
     * Get File sample
     *
     * @param {string} token
     * @param {string} fileId
     *
     * @return {Promise<object>}
     */
    const getFileSample = async (token, fileId) => {
        try {
            const url = replaceEndPointVariable(sampleEndPoint, ':id', fileId);
            const fileSample = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            delete fileSample.data._id
            return fileSample.data
        } catch (error) {
            const message = error.response.data.message ?? 'Something went wrong and cannot get file sample.'
            setAlert({...alert, stats: error.status, message})
        }
    }

    /**
     * Get File
     *
     * @param {string} token
     * @param {string} fileId
     *
     * @return {Promise<object>}
     */
    const getFile = async (token, fileId) => {
        try {
            const url = replaceEndPointVariable(getFileEndPoint, ':id', fileId);
            const file = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            return file.data
        } catch (error) {
            const message = error.response.data.message ?? 'Something went wrong and cannot retrieve this file.'
            setAlert({...alert, stats: error.status, message })
        }
    }

    /**
     * Replace variable parameter in endpoint string
     *
     * @param {string} url
     * @param {string} variable
     * @param {string} value
     *
     * @return {string}
     */
    const replaceEndPointVariable = (url, variable, value) => {
        return url.replace(variable, value)
    }

    /**
     * Reset Current state to default
     */
    const reset = () => {
        setSample(undefined)
        setFile(undefined)
        setSelections({})
        setStep(0)
        setChart(undefined)
    }

    return (
        <VisualizationContext.Provider
            value={{
                init, // Function to initialize chart setup process
                getChartData, // api call to retrieve processed chart data
                reset, // reset current context state
                hidden, // closeable statistics: mean, median, mode, complexity, min, max
                setHidden,
                showControls, // Open/close Control Panel
                setShowControls,
                chart, // Current data for the chart
                setChart,
                file, // File for file details
                setFile,
                keys, // Current selected keys of process chart data
                setKeys,
                xAxis, // Current property set as the x-axis
                setXAxis,
                availableCharts, // sorted array of different chart types
                setAvailableCharts,
                chartType, // current chart type
                setChartType,
                step, // step in chart setup process
                setStep,
                showChartSelection, // Open/Close chart selection popup
                setShowChartSelection,
                sample, // Sample of data(actually a datamap generated on source intake)
                setSample,
                selections, // selected properties from sample.
                setSelections,
                loading, // Current loading state
                setLoading,
                ready, // state of chart being ready for render
                setReady,
                sampleSize, // sample size of data set: default 100
                setSampleSize,
                stats, // mean, median, mode, complexity, min, max: default on x-axis value
                setStats,
                generalStat,
                setGeneralStat,
                calculateStatsFromSampleSize, // if false && generalStat !== xAxis - calculate stats for full data set based on generalStat
                setCalculateStatsFromSampleSize
            }}>
            {children}
        </VisualizationContext.Provider>
    )
}
