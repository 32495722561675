import {useContext} from "react";
import {MapBuilderContext} from "../../context/MapBuilderContext";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export const RenderSample = ({ data, handleClick, parentKey = '', isLast = true, searchingFor = undefined}) => {
    const {isLoading} = useContext(MapBuilderContext)

    if (typeof data !== 'object' || data === null) return null;

    const protectedKeys = [
        'path',
        'type',
        'schema'
    ];
    const keys = Object.keys(data);

    /**
     * Handles the drag start event and sets the drag data.
     * The drag data is set as a string in the format 'key: path'.
     *
     * @param {DragEvent} e - The drag start event object.
     * @param {string} key - The key to be set as drag data.
     * @param {string} path - The path to be set as drag data.
     */
    const handleDragStart = (e, key, path) => {
        e.dataTransfer.setData('text/plain', `${key}: ${path}`);
    };

    const showOnlyKey = (key) => {
        return key.split('.').pop();
    }

    return (
        <div className="pretty-map relative">
            {keys.map((key, index) => {
                    // Skip rendering numeric keys (array indices)
                    if (Array.isArray(data) && !isNaN(key)) {
                        return <RenderSample key={index} data={data[key]} handleClick={handleClick} parentKey={parentKey} isLast={index === data.length - 1} searchingFor={searchingFor} />;
                    }

                    const path = parentKey ? `${parentKey}.${key}` : key;
                    const isLastKey = index === keys.length - 1;
                    const displayKey = showOnlyKey(key);

                if(key === 'path' || key === 'type') {
                    return;
                }
                    return (
                        <div key={path} className={`node ${isLastKey ? 'last' : ''}`} >
                            <div
                                className={classNames(searchingFor !== undefined && searchingFor.trim() !== path || protectedKeys.includes(displayKey) ? 'hidden' : '', "key hover:bg-gray-50 py-1 px-3 border rounded-lg my-1")}
                                onClick={() => isLoading || protectedKeys.includes(displayKey) ? null : handleClick(displayKey,  path)}
                                id={path}
                                draggable={!isLoading}
                                onDragStart={(e) => handleDragStart(e, displayKey, path)}>
                                {displayKey}
                            </div>
                            {typeof data[key] === 'object' && data[key] !== null ? (
                                <RenderSample data={data[key]} handleClick={handleClick} parentKey={path} isLast={isLastKey} searchingFor={searchingFor}/>
                            ) : null}
                        </div>
                );
                })}
            </div>
    );
};
