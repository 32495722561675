import {useContext, useEffect, useState} from 'react';

// Context
import {AuthContext} from '../../../context/AuthContext';
import {AlertContext} from '../../../context/AlertContext';

// Hooks
import {useGetFiles} from "../../../hooks/useFiles";

import {FileNameInput} from "./FileNameInput";
import {UploadContextMenu} from "./UploadContextMenu";
import {UploadTimestamp} from "./UploadTimestamp";


export const UploadLog = ({refreshTrigger, setRefreshTrigger}) => {
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);
    const [uploaded, setUploaded] = useState([]);
    const [editUpload, setEditUpload] = useState(undefined)
    const { data: uploadedFiles, error, isLoading } = useGetFiles(authenticated, refreshTrigger);

    useEffect(() => {
        if (Array.isArray(uploadedFiles) && uploadedFiles.length > 0) {
            setUploaded(uploadedFiles);
        } else {
            setUploaded([])
        }
    }, [uploadedFiles]);

    useEffect(() => {
        if (error) {
            setAlert({ ...alert, status: 500, message: 'Something went wrong. Cannot retrieve your uploads...' });
        }
    }, [error]);


    return (
        <div className="mt-10">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Previously Uploaded</h3>
            {isLoading
                ? null
                : <ul
                    className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg divide-y divide-gray-200 bg-white">
                    {uploaded.length === 0
                        ? <li className="px-6 py-10 text-gray-400 text-center font-semibold">
                                No Data Sources Provided
                            </li>
                        : uploaded.map((file) => (
                            <li
                                key={file._id}
                                className="px-6 flex items-center justify-between gap-x-6 py-5">
                                <div className="w-full">
                                    <div className="flex gap-x-3 w-full">
                                        <FileNameInput
                                                editUpload={editUpload}
                                                setEditUpload={setEditUpload}
                                                setRefreshTrigger={setRefreshTrigger}
                                                file={file}/>
                                    </div>
                                    <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500 px-3">
                                        <UploadTimestamp date={file.createdAt} />
                                    </div>
                                </div>
                                <div className="flex flex-none items-center gap-x-4">
                                    <UploadContextMenu setRefreshTrigger={setRefreshTrigger} file={file} />
                                </div>
                            </li>
                        ))}
                </ul>}
        </div>
    );
}
