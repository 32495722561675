import {FaTimes} from "react-icons/fa";
import {RenderSample} from "../../Maps/RenderSample";
import {useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";
import {AlertContext} from "../../../context/AlertContext";
import {ControlLabel} from "./ControlLabel";
import {ControlDetail} from "./ControlDetail";

export const SampleSelectionControl = () => {
    const {
        xAxis,
        sample,
        selections,
        setSelections,
    } = useContext(VisualizationContext);
    const {alert, setAlert} = useContext(AlertContext);


    /**
     * Add property
     */
    const setPropertiesToVisualize = (displayKey, path) => {
        const newProps = {...selections};

        if(!newProps.hasOwnProperty(displayKey)) {
            newProps[displayKey] = path
        }

        setSelections(newProps)
    }

    /**
     * Remove selected property
     */
    const removeProperty = key => {
        if(key === xAxis) {
            setAlert({...alert, status: 500, message: 'Cannot delete current X axis from selected properties.'})
        } else {
            const newProps = {...selections}
            delete newProps[key]
            setSelections(newProps)
        }
    }

    return (
        <div className={'bg-gray-100 p-3 rounded-lg'}>
            <ControlLabel label={'Selected'}/>
            <ControlDetail detail={'Clicking a property below will add a data point to the current data set being visualized.'} />
            {Object.keys(selections).length > 0 ?
                <div className={'bg-white p-3 rounded-lg my-3 text-left'}>
                    <div className={'flex flex-row gap-3 flex-wrap'}>
                        {Object.entries(selections).map(([key, value]) => {
                            return (
                                <button key={Math.random()} onClick={() => removeProperty(key)} className={'text-xs bg-gray-100 p-2 rounded flex flex-row items-center gap-2'}>{key} <FaTimes className={'text-red-500'}/></button>
                            )
                        })}
                    </div>
                </div>
                : null}
            <p className={'text-sm font-medium mb-2'}>Add Properties</p>
            <div className={'flex flex-col gap-2 my-3'}>
                <RenderSample data={sample} handleClick={setPropertiesToVisualize} />
            </div>
        </div>
    )
}
