import { createContext, useEffect, useState } from "react";
import { getCookie } from "../hooks/getCookie";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [authenticated, setAuthenticated] = useState(null)

    useEffect(() => {
        checkAuthenticated()
    }, [])

    /**
     * Checks the current authentication status by reading the authentication cookie.
     * Sets the authenticated state to `true` if the cookie indicates authentication,
     * otherwise sets it to `false`.
     */
    const checkAuthenticated = () => {
        const cookie = getCookie('auth')

        cookie !== null || cookie !== 'null' ?
            setAuthenticated(cookie) :
            setAuthenticated(null)

            checkUrl(cookie);
    }

    const checkUrl = (cookie) => {
        const currentUrl = window.location.pathname

        if(cookie === null && currentUrl !== '/' && currentUrl !== '/login' && currentUrl !== '/register') {
            window.location.href = '/'
        }
    }

    return (
        <AuthContext.Provider value={{authenticated, setAuthenticated}}>
            {children}
        </AuthContext.Provider>
    )
}
