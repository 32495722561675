import {Fragment, useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";
import {ControlLabel} from "./ControlLabel";
import {ControlDetail} from "./ControlDetail";

export const XaxisControl = () => {
    const {
        keys,
        xAxis,
        setXAxis
    } = useContext(VisualizationContext);

    return (
        <Fragment>
            {keys.length > 0 ?
                <div className={'bg-gray-100 p-3 rounded-lg'}>
                    <p className={'text-sm font-medium mb-2'}></p>
                    <ControlLabel label={'Axis Selection'}/>
                    <ControlDetail detail={'Select a different x-axis to render your chart based on "x".'} />
                    <div className={'flex flex-col gap-2 my-3'}>
                        <select onChange={e => setXAxis(e.target.value)} className={'rounded-lg capitalize'} autoFocus={true} defaultValue={xAxis}>
                            {xAxis ? <option value={xAxis}>{xAxis}</option> : <option>Select An Option</option>}
                            {keys.map(item => {
                                if(item === xAxis) return;

                                return (
                                    <option key={Math.random().toString()} value={item}>{item}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                : null}
        </Fragment>
    )
}
