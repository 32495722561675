import {
    BsFileBinary,
    BsFiletypeCsv,
    BsFiletypeJson,
    BsFiletypeXls,
    BsFiletypeXlsx,
    BsFiletypeXml
} from "react-icons/bs";

export const ACTIONS = [
    {
        name: 'download',
        available: true,
        optionsLabel: '',
        options: [],
        priority: 1
    }, {
        name: 'convert',
        available: true,
        optionsLabel: 'Select Format',
        options: [
            'csv', 'xml', 'xlsx',
            // 'binary', 'json', 'xls', 'txt', 'bson'
        ],
        priority: 2
    },
    {
        name: 'map',
        available: true,
        optionsLabel: '',
        options: [],
        priority: 3
    },
    {
        name: 'visualize',
        available: true,
        optionsLabel: '',
        options: [],
        priority: 4
    },
    // {
    //     name: 'rationalize',
    //     available: false,
    //     optionsLabel: '',
    //     options: [],
    //     priority: 5
    // }, {
    //     name: 'connect',
    //     available: false,
    //     optionsLabel: '',
    //     options: [],
    //     priority: 6
    // },
    {
        name: 'view meta',
        available: true,
        optionsLabel: '',
        options: [],
        priority: 7
    },
    {
        name: 'delete',
        available: true,
        optionsLabel: '',
        options: [],
        priority: 8
    }
];

export const FILE_CONV_STATUSES = {
    original: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    conversion: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    mapped: 'text-blue-800 bg-blue-50 ring-blue-600/20'
};

export const APPROVED_FILE_TYPES = {
    csv: {icon: BsFiletypeCsv},
    xml: {icon: BsFiletypeXml},
    binary: {icon: BsFileBinary},
    json: {icon: BsFiletypeJson},
    xls: {icon: BsFiletypeXls},
    xlsx: {icon: BsFiletypeXlsx},
    txt: {icon: null},
    bson: {icon: null},
};
