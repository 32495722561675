/* eslint-disable array-callback-return */
export const UploadCard = ({upload, setUploads}) => {
    /**
   * Handles the process of uploading files. This function updates the state
   * by adding the newly uploaded files to the existing list of files.
   *
   * @param {Event} e - The event object triggered upon selecting new files.
   */
    const handleFileUpload = (e) => {
        let files = Array.from(e.target.files);

        setUploads({
            ...upload,
            files: files
        })
    }

    /**
     * Retrieves the names of all the uploaded files.
     *
     * @returns {string} A comma-separated string of all uploaded file names.
     */
    const getFileNames = () => {
        const names = []

            upload
                .files
                .map(uploaded => {
                    return names.push(uploaded.name)
                })

        return names.join(', ')
    }

    return (
        <label
            htmlFor="file_upload"
            className='pb-20 pt-20 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-ssg-400 focus:outline-none focus:ring-2 focus:ring-ssp-500 focus:ring-offset-2 cursor-pointer mb-4'>
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"/>
            </svg>
            <span className="mt-2 block text-sm font-semibold text-gray-900">
                {upload.files
                    ?.length > 0
                        ? `Select action for ${getFileNames()}`
                        : 'Upload Data Source'}</span>
            <span className="mt-2 block text-xs font-thin text-gray-900 italic">.csv, .xml, .xlsx, .xls</span>
            <input
                type="file"
                onChange={handleFileUpload}
                multiple={true}
                id='file_upload'
                className="hidden"/>
        </label>
    )
}
