import React, {Fragment} from "react";

export const ComingSoon = ({available}) => {
    return (
        <Fragment>
            {!available
                ? <span
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-ssg-400 px-1.5 py-0.5 text-xs text-ssg-600 absolute -top-2 -right-2">
                        <svg className="h-1.5 w-1.5 fill-ssg-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3}/>
                        </svg>
                        Coming Soon
                    </span>
                : null}
        </Fragment>
    )
}
