import { useEffect, useState } from 'react';
import {ACTIONS} from "../staticData/static";

export const useUploadAction = (actionName) => {
    const [action, setAction] = useState(null);

    useEffect(() => {
        const foundAction = ACTIONS.find(action => action.name === actionName);
        setAction(foundAction);
    }, []);

    return action;
};
