import {useRef, useState, useEffect} from "react";
import {QuestionTooltip} from "../alerts/QuestionTooltip";

const style = {
    strokeWidth: 24,
    strokeLinecap: "round"
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const NumericGauge = ({numericValue, label, info, max = 100}) => {
    const [circlePos, setCirclePos] = useState({ x: 100, y: 100 });
    const pathRef = useRef(null);

    const percentageOfMax = (numericValue / max) * 100;

    useEffect(() => {
        if (pathRef.current) {
            const pathLength = pathRef.current.getTotalLength();
            const percentageOfMax = (numericValue / max) * 100; // This calculates the percentage
            const point = pathRef.current.getPointAtLength(percentageOfMax * pathLength / 100);
            setCirclePos({ x: point.x, y: point.y });
        }
    }, [numericValue, max]);

    const isFloat = (n) =>  {
        return n % 1 !== 0;
    }

    const isMoreThanHalf = () => {
        return numericValue > max / 2;
    }
    return (
        <div className={'relative'}>
            <svg id="paths" className="mx-auto" viewBox="33 50 290 50"
                 width="200"
                 height="200">
                <path d="M100 100 A 40 40 90 0 1 250 100" className={classNames(isMoreThanHalf() ? 'stroke-red-200' : 'stroke-green-200', 'fill-transparent opacity-40')}
                      style={{
                          ...style,
                      }} />
                <path
                    ref={pathRef}
                    pathLength={'100'}
                    d="M100 100 A 40 40 90 0 1 250 100"
                    className={classNames(isMoreThanHalf() ? 'stroke-red-200' : 'stroke-green-200', 'fill-transparent')}
                    style={{
                        ...style,
                        strokeDasharray: `${percentageOfMax}, ${200 - percentageOfMax}`,
                        strokeDashoffset: '0'
                    }}
                />
                <circle
                    cx={circlePos.x}
                    cy={circlePos.y}
                    r="8"
                    fill="white"
                />
            </svg>
            <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 text-gray-600 flex flex-col gap-2 text-center">
                <p className={'mb-1 text-md font-bold'}>{isFloat(numericValue) ? numericValue.toFixed(2) : numericValue ?? 'N/a'}</p>
                <div className={'flex flex-row items-center gap-2 relative w-max'}>
                    <p className="font-medium text-sm capitalize">{label}</p>
                    {!info ? null : <QuestionTooltip content={info}/>}
                </div>
            </div>
        </div>
    )
}
