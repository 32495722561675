import {Fragment, useContext, useEffect, useState} from "react";
import {ChartSelector} from "./Popups/ChartSelector";
import {VisualizeFile} from "./VisualizeFile";
import {VisualizationContext} from "../../context/VisualizationContext";
import {RenderSample} from "../Maps/RenderSample";
import {AuthContext} from "../../context/AuthContext";
import {useParams} from "react-router-dom";
import {GeneralOverlay} from "../Overlays/GeneralOverlay";
import {VisualizationStepButtons} from "./VisualizationStepButtons";
import {VisualizationPropertySelector} from "./Popups/VisualizationPropertySelector";
import {VisualizationLoading} from "./Popups/VisualizationLoading";
import {BsCommand} from "react-icons/bs";
import {MdKeyboardControlKey} from "react-icons/md";
import {FaKeyboard} from "react-icons/fa";
import {ControlButtonHint} from "../alerts/ControlButtonHint";

export const VisualizationContainer = () => {
    const params = useParams();
    const {authenticated} = useContext(AuthContext);
    const {step, init, sample, setLoading, ready, showControls, setShowControls } = useContext(VisualizationContext);

    const [hasSeen, setHasSeen] = useState(false)
    useEffect(() => {
        function handleKeyDown(event) {
            // Check if ctrl or command key is pressed and the key is 'o'
            if ((event.ctrlKey || event.metaKey) && event.key === 'o') {
                event.preventDefault(); // Prevents the browser's default behavior for ctrl+o
                setShowControls(prevState => !prevState); // Toggle showControls state
                setHasSeen(true)
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            // Remove the event listener on component unmount
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if(!sample) {
            init(authenticated, params.fileId)
        }

        if(step === 2) {
            setLoading(true)
        }

    }, [sample, step]);

    if(!sample) return;

    return (
        <Fragment>
            {
                hasSeen ? null : <ControlButtonHint />
            }

            {/* Step 0: select chart type */}
            {
                parseInt(step) === 0 ? <ChartSelector /> : null
            }

            {/* Step 1: Select data points */}
            {
                parseInt(step) === 1 ? <VisualizationPropertySelector /> : null
            }

            {/* Step 2: Show loading & make API call to retrieve processed data points */}
            {
                parseInt(step) === 2 ? <VisualizationLoading /> : null
            }

            {ready ? <VisualizeFile /> : null}

        </Fragment>
    )
}
