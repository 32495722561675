import React, { useContext, useEffect, useState} from 'react'
import {RenderSample} from "./RenderSample";
import {MutationBuilder} from "./MutationBuilder";
import {useGetFile, useGetFileSample} from "../../hooks/useFiles";
import {AuthContext} from "../../context/AuthContext";
import {AlertContext} from "../../context/AlertContext";
import {useParams} from "react-router-dom";
import {MapBuilderContext} from "../../context/MapBuilderContext";
import {MapPropertySearch} from "./MapPropertySearch";
import axios from "axios";
import {MapActionsMenu} from "./MapActionsMenu";
import {FaSpinner} from "react-icons/fa";
import {MapResetButton} from "./MapResetButton";
import {AddBuildablePropertyButton} from "./AddBuildablePropertyButton";

export const MappingPanel = () => {
    const {map, setMap, isLoading, setIsLoading} = useContext(MapBuilderContext);
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);
    const params = useParams();

    const { data: file } = useGetFile(authenticated, params.fileId)
    const { data, error } = useGetFileSample(authenticated, params.fileId)
    const [searchingFor, setSearchingFor] = useState(undefined);

    useEffect(() => {
        if (data) {
            setMap(prevMap => ({...prevMap, source: data}));

            const fetchSearchableKeys = async () => {
                try {
                    const res = await axios.post('/api/v1/datamaps/method/source-path', {
                        source: data,
                        mutation: null,
                        path: null,
                        value: null
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${authenticated}`
                        }
                    })

                    const keys = flattenSourceKeys(res.data)
                    setMap(prevMap => ({...prevMap, searchable: keys}))
                } catch (error) {
                    setAlert({...alert, message: error.response.data.message, status: error.response.data.status})
                }
            };

            fetchSearchableKeys();
        }

    }, [data, alert, authenticated]);

    useEffect(() => {
        if (error) {
            setAlert({ ...alert, status: 500, message: 'Something went wrong. Cannot retrieve your uploads...' });
        }
    }, [error, alert, setAlert]);

    /**
     *  Get path of desired property to map
     *
     *  @param {string} path
     *   @param {string} key
     *  @sets {mutation}
     */
    const getPropertyPath = async (path, key) => {
        if(map.mutation.hasOwnProperty(key)) {
            setAlert({...alert, status: 400, message: `Root property of ${key} already exists.`})
        } else {
            setIsLoading(true)
            try {
                const res = await axios.post('/api/v1/datamaps/method/add', {
                    source: map.source,
                    mutation: map.mutation,
                    path: path, // mutation path
                    value: key // source path
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authenticated}`
                    }
                })

                setMap({...map, mutation: res.data.modified})
                setIsLoading(!isLoading)
            } catch (error) {
                setAlert({...alert, message: error.response.data.message, status: error.response.data.status})
            }
            setIsLoading(false)
        }
    }

    /**
     * Flattens the keys of a nested object and returns a new object with sorted keys.
     * The keys of the new object are the leaf nodes of the input object, and the values are the dot-notation paths from the root to the leaf.
     *
     * @function
     * @param {Object} obj - The input object to be flattened.
     * @returns {Object} - A new object with sorted keys, where each key is a leaf node of the input object, and each value is the dot-notation path to that leaf.
     */
    const flattenSourceKeys = (obj) => {
        let flattened = {};

        /**
         * Helper function to recursively flatten the keys of the input object.
         *
         * @param {Object} currentObj - The current object being processed.
         * @param {string} parentPath - The dot-notation path from the root to the current object.
         */
        function helper(currentObj, parentPath) {
            for (const key in currentObj) {
                if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
                    const currentPath = parentPath ? `${parentPath}.${key}` : key;

                    // Include non-leaf nodes in the flattened object
                    if (parentPath) {
                        flattened[key] = currentPath;
                    } else {
                        flattened[key] = key;
                    }

                    if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
                        // If the property is an object or an array, recurse into it
                        helper(currentObj[key], currentPath);
                    } else {
                        // If the property is a primitive value, set its value to the current path
                        flattened[key] = currentPath;
                    }
                }
            }
        }

        helper(obj, '');
        return Object.keys(flattened)
            .sort()
            .reduce((obj, key) => {
                obj[key] = flattened[key];
                return obj;
            }, {});
    }

    /**
     * Handles the selection of a key from the flattened object by setting the searchingFor state.
     *
     * @function
     * @param {string} path - The selected key from the flattened object, in the format "key: path".
     */
    const handleMapSearch = (path) => {
        path ?
            setSearchingFor(path.split(':')[1]) :
                setSearchingFor(undefined)
    }

    return (
        <div className={"px-6 relative"}>
            <div className={"sticky top-5 z-50 flex justify-end items-center gap-4"}>
                {isLoading ? <FaSpinner className={"spin text-2xl text-ssg"} /> : null}
                {
                    Object.keys(map.mutation).length > 0 ? <MapActionsMenu /> : null
                }
            </div>
            <div id={"map-builder-container"} className="grid grid-cols-1 sm:grid-cols-2 sm:grid-flow-row gap-x-4 gap-y-4 bg-transparent shadow-none dialog-box ">
                <div id="sample-render" className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 my-auto overflow-y-scroll  flex flex-col">
                    <div className={"mb-3"}>
                        {map.searchable === undefined || map.searchable === {} ? null : <MapPropertySearch keys={map.searchable} handleKeySelect={handleMapSearch}/>}
                    </div>
                    <label className={"my-2 text-sm font-semibold text-gray-900"}>Sample Data For: <span className={"p-2 rounded bg-gray-100 text-xs"}>{file?.filename}</span></label>
                    <AddBuildablePropertyButton />

                    <RenderSample data={map.source} handleClick={getPropertyPath} searchingFor={searchingFor}/>
                </div>
                <div  id="variant-builder" className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 overflow-y-scroll flex flex-col">
                    <label className={"my-2 text-sm font-semibold text-gray-900"}>Data Map</label>

                    <MapResetButton />
                    <MutationBuilder mutation={map.mutation}  />
                </div>
            </div>
        </div>

    )
}
