import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { navigation } from './navigation';
import LogoMain from '../../assets/syndsynclogo.png';
import { MenuContext } from '../../context/MenuContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Sidebar = () => {
  const location = useLocation();
  const {menuOpen, setMenuOpen} = useContext(MenuContext)

  return (
    <div className="flex flex-col w-64 gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
      <div className="flex h-16 shrink-0 items-center">
        <img
          className="h-12 w-auto"
          src={LogoMain}
          alt="Your Company"
        />
      </div>

      <ul className="-mx-2 space-y-1">
        {navigation.map((item) => (
          <li key={item.name}>
            <Link
              to={item.href}
              onClick={e => window.innerWidth <= 768 ? setMenuOpen(!menuOpen) : null}
              className={classNames(
                location.pathname === item.href
                  ? 'bg-gray-800 text-white'
                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
              )}
            >
              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
