import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {AlertContext} from "../context/AlertContext";
import {MenuContext} from "../context/MenuContext";
import {useGetFiles} from "../hooks/useFiles";
import {VisualizationContext} from "../context/VisualizationContext";
import {NoSourceState} from "../components/EmptyStates/NoSourceState";

const extColors = {
    csv: 'bg-pink-600',
    xml: 'bg-purple-600',
    xls: 'bg-yellow-500',
    xlsx: 'bg-green-500'
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export const Visualize = () => {
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);
    const {setMenuOpen} = useContext(MenuContext)
    const {setChart, setFile, reset} = useContext(VisualizationContext);
    const { data, error } = useGetFiles(authenticated, 0);

    const [files, setFiles] = useState([])

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            setFiles(data);
        }

        return () => {
            setChart(undefined)
            setFile(undefined)
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setAlert({ ...alert, status: 500, message: 'Something went wrong. Cannot retrieve your uploads...' });
        }
    }, [alert, setAlert, error]);

    const handleClick = (id) => {
        setMenuOpen(false)
        reset()

        window.location = `/visualize/${id}`
    }

    const renderFileList = () => {
        return (
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                {files.map((file) => (
                    <li key={file._id} className="col-span-1 flex rounded-md shadow-sm">
                        <div
                            className={classNames(
                                extColors[file.metadata?.extension],
                                'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                            )}
                        >
                            {file.metadata?.extension}
                        </div>
                        <button onClick={e => handleClick(file._id)} className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white hover:bg-gray-50">
                            <div className="flex-1 truncate px-4 py-2 text-sm text-left">
                                <span className="font-medium text-gray-900 hover:text-gray-600 capitalize">
                                    {file.filename}
                                </span>
                                <p className="text-gray-500">{file.metadata.bytes} Bytes</p>
                            </div>
                        </button>
                    </li>
                ))}
            </ul>
        )
    }

    return (
        <div className='p-4 lg:p-8'>
            <h2 className="text-xl font-semibold text-gray-500">Select a Source to Visualize</h2>
            {files.length > 0 ? renderFileList() : <NoSourceState />}
        </div>
    )
}
