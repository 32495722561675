import {ArrowDownIcon, ArrowUpIcon} from '@heroicons/react/24/outline';

function classNames(...classes) {
    return classes
        .filter(Boolean)
        .join(' ');
}

export const NumericStats = ({stats}) => {
    return (
        <div className="px-4 py-8 sm:px-6 lg:px-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {stats.map((item) => (
                    <div key={Math.random()} className="bg-white overflow-hidden shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                <div className="flex items-center">
                                    {/* <item.icon className="h-6 w-6 text-gray-400" aria-hidden="true"/> */}
                                    <span className="">{item.name}</span>
                                </div>
                            </dt>
                            <dd className="mt-1 text-3xl font-semibold text-gray-900 sm:text-4xl">
                                {item.stat}
                            </dd>
                            <dd>
                                <p
                                    className={classNames(item.changeType === 'increase'
                                    ? 'ml-2 flex items-baseline text-sm font-semibold text-green-600'
                                    : 'ml-2 flex items-baseline text-sm font-semibold text-red-600', 'mt-2 text-sm font-semibold')}>
                                    {item.changeType === 'increase'
                                        ? (<ArrowUpIcon
                                            className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                                            aria-hidden="true"/>)
                                        : (<ArrowDownIcon
                                            className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                                            aria-hidden="true"/>)}
                                    <span className="sr-only">
                                        {' '}
                                        {item.changeType === 'increase'
                                            ? 'Increased'
                                            : 'Decreased'}
                                        by{' '}
                                    </span>
                                    {item.change}
                                </p>
                            </dd>
                        </div>
                    </div>
                ))}
            </dl>
        </div>
    );
}
