import { XMarkIcon} from "@heroicons/react/20/solid";
import React, {useContext} from "react";
import {MapBuilderContext} from "../../context/MapBuilderContext";

export const MapResetButton = () => {
    const {map, setMap} = useContext(MapBuilderContext);

    const resetMap = () => {
        setMap({...map, mutation: {}})
    }

    return (
        <div className={"my-3"}>
            {Object.keys(map.mutation).length > 0 ?
                <button onClick={resetMap} className={"flex flex-row items-center gap-2 rounded-full border border-red-500 hover:bg-red-500 hover:text-white duration-200 pr-4"}>
                    <div className="rounded-full bg-red-500  p-1 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <span className={"font-light text-sm"}>Reset Map</span>
                </button> : null}
        </div>
    )

}
