import {Menu, Transition} from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
import {Fragment} from "react";
import {DownloadActionButton} from "../UploadActionButtons/DownloadActionButton";
import {ConvertActionButton} from "../UploadActionButtons/ConvertActionButton";
import {ViewMetaActionButton} from "../UploadActionButtons/ViewMetaActionButton";
import {MapActionButton} from "../UploadActionButtons/MapActionButton";
import {DeleteActionButton} from "../UploadActionButtons/DeleteActionButton";
import {VisualizeActionButton} from "../UploadActionButtons/VisualizeActionButton";

export const UploadContextMenu = ({file, setRefreshTrigger}) => {
    return (
        <Menu as="div" className="relative flex-none">
            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items
                    className="absolute right-0 z-50 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none p-2">
                    {/*Download*/}
                    <DownloadActionButton
                        fileId={file._id}
                        styles={'block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100 w-full text-left rounded capitalize my-1'} />

                    {/*Convert*/}
                    <ConvertActionButton
                        fileId={file._id}
                        styles={"inline-flex w-full justify-center gap-x-1.5 px-3 rounded-md text-xs md:text-sm capitalize"} />

                    {/*View Meta*/}
                    <ViewMetaActionButton
                        fileId={file._id}
                        styles={'block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100 w-full text-left rounded capitalize my-1'} />

                    {/*Map*/}
                    {
                        file.metadata.mapped === true ? null : <MapActionButton
                            fileId={file._id}
                            styles={'block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100 w-full text-left rounded capitalize my-1'} />
                    }

                    {/*View Meta*/}
                    <VisualizeActionButton
                        fileId={file._id}
                        styles={'block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100 w-full text-left rounded capitalize my-1'} />


                    {/*Delete*/}
                    <DeleteActionButton
                        fileId={file._id}
                        setRefreshTrigger={setRefreshTrigger}
                        styles={'block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-100 w-full text-left rounded capitalize my-1'} />
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
