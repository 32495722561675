import {Fragment, useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";

export const HiddenStatControl = () => {
    const {
        hidden,
        setHidden
    } = useContext(VisualizationContext);

    const showHidden = term => {
        const show = hidden.filter(item => item !== term);
        setHidden(show)
    }
    return (
        <Fragment>
            {hidden.length > 0 ?
                <div className={'bg-gray-100 p-3 rounded-lg'}>
                    <p className={'text-sm font-medium mb-2'}>Hidden Stats</p>
                    <div className={'flex flex-row gap-2 items-center flex-wrap'}>
                        {hidden.map(term => {
                            return (
                                <button key={term} onClick={_e => showHidden(term)} className={'bg-white px-2 py-1 text-sm rounded-lg hover:bg-ssg hover:text-white'}>{term}</button>
                            )
                        })}
                    </div>
                </div> : null}
        </Fragment>
    )
}
