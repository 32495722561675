import {createContext, useState} from "react";

export const MapBuilderContext = createContext();

export const MapBuilderProvider = ({children}) => {
    const [map, setMap] = useState({
        source: {},
        mutation: {},
        searchable: undefined,
        name: '',
        description: ''
    });
    const [isLoading, setIsLoading] = useState(false)

    const reset = () => {
        setMap({
            source: {},
            mutation: {},
            searchable: undefined,
            name: '',
            description: ''
        })
    }
    return (
        <MapBuilderContext.Provider value={{map, setMap, isLoading, setIsLoading, reset}}>
            {children}
        </MapBuilderContext.Provider>
    )
}
