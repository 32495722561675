import {Fragment} from 'react'
import {OptinalFileActionButton} from './OptinalFileActionButton';
import {FileActionButton} from './FileActionButton';

const actions = [
    {
        name: 'store',
        available: true,
        optionsLabel: '',
        options: []
    }, {
        name: 'convert',
        available: true,
        optionsLabel: 'Select Format',
        options: [
            'csv', 'xml', 'xlsx',
            // 'binary', 'json', 'xls', 'txt', 'bson'
        ]
    }, {
        name: 'map',
        available: false,
        optionsLabel: '',
        options: []
    }, {
        name: 'standardize',
        available: false,
        optionsLabel: '',
        options: []
    }
];

export const UploadActions = ({handleSelection}) => {
    const handleSelectWithOption = (action, option) => {
        handleSelection(action, option)
    }
    const handleSelect = (action) => {
        handleSelection(action)
    }

    return (
        <Fragment>
            <h2
                className="text-lg font-medium text-gray-900 mb-2 flex flex-row items-center gap-3">Data Actions
            </h2>

            <div className='grid grid-cols-1 space-y-4'>
                {actions.map((action, _index) => {
                    return action.options.length > 0
                        ? <OptinalFileActionButton
                                key={Math.random()}
                                action={action}
                                handleClick={handleSelectWithOption}/>
                        : <FileActionButton
                            key={Math.random()}
                            action={action}
                            handleClick={handleSelect}
                            showBadge={true}/>
                })}
            </div>
        </Fragment>
    )
}
