import {Fragment} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'
import {UploadActions} from './UploadActions'

export const UploadActionPanel = ({
    open,
    setOpen,
    currentUpload,
    setCurrentUpload,
    reset,
    confirm
}) => {
    const handleCancel = () => {
        reset()
        setOpen(!open)
    }

    /**
     * Set source actions
     */
    const handleActionSelect = (action, option) => {
        if (option) {
            setCurrentUpload({
                ...currentUpload,
                action,
                option
            })
        } else {
            setCurrentUpload({
                ...currentUpload,
                action
            })
        }
    }

    /**
     * Check if files contain a header row. 
     */
    const checkPossiblyIncludesHeaderRow = () => {
        let includesHeader = false;
        const files = currentUpload.files;

        files?.map(file => {
            if(file?.name.includes('csv')) {
                includesHeader = true;
            }

            return includesHeader;
        })
        
        return includesHeader;
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={handleCancel}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div
                            className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full">
                                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                onClick={() => handleCancel()}>
                                                <span className="absolute -inset-2.5"/>
                                                <span className="sr-only">Close panel</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                Upload Action Panel
                                            </Dialog.Title>
                                        </div>
                                        <div className="relative flex-1 px-4 sm:px-6">
                                            <div className="grid grid-cols-1 space-y-3 mt-4 mb-10">
                                                <div
                                                    className='flex flex-row items-center gap-3 bg-gray-100 p-3 rounded-lg justify-between'>
                                                    <label htmlFor="" className='font-medium text-gray-500'>Selection Action:</label>
                                                    <p className='capitalize font-normal '>{currentUpload.action ?? 'Not selected'}</p>
                                                </div>

                                                {currentUpload.action === 'convert'
                                                    ? <div
                                                    className='flex flex-row items-center gap-3 bg-gray-100 p-3 rounded-lg justify-between'>
                                                    <label htmlFor="" className='font-medium text-gray-500'>Convert Option:</label>
                                                    <p className='capitalize font-normal '>{currentUpload.option ?? 'Not selected'}</p>
                                                </div>
                                                    : null}

                                                {
                                                    checkPossiblyIncludesHeaderRow() ?
                                                    <div
                                                    className='flex flex-row items-center gap-3 bg-gray-100 p-3 rounded-lg justify-between'>
                                                    <label htmlFor="includesHeader" className='font-medium'>Includes Header Row?</label>
                                                    <input
                                                        type="checkbox"
                                                        name="includesHeader"
                                                        id=""
                                                        onChange={e => setCurrentUpload({
                                                        ...currentUpload,
                                                        includesHeader: e.target.checked
                                                    })}
                                                        checked={currentUpload.includesHeader
                                                        ? currentUpload.includesHeader
                                                        : false}/>
                                                </div>
                                                    : null
                                                }
                                                

                                                {currentUpload.action === 'convert'
                                                    ? <div
                                                            className='flex flex-row items-center gap-3 bg-gray-100 p-3 rounded-lg justify-between'>
                                                            <label htmlFor="keepOriginal" className='font-medium'>Keep original source file?</label>
                                                            <input
                                                                type="checkbox"
                                                                name="keepOriginal"
                                                                id=""
                                                                onChange={e => setCurrentUpload({
                                                                ...currentUpload,
                                                                keepOriginal: e.target.checked
                                                            })}
                                                                checked={currentUpload.keepOriginal
                                                                ? currentUpload.keepOriginal
                                                                : false}/>
                                                        </div>
                                                    : null}

                                                    
                                            </div>

                                            <UploadActions handleSelection={handleActionSelect}/>

                                            <div
                                                className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                    onClick={handleCancel}>
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="inline-flex w-full justify-center rounded-md bg-ssg-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-ssg-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ssg-600 sm:col-start-2 capitalize"
                                                    onClick={confirm}>
                                                    Confirm
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
