import React from 'react';
import ReactDOM from 'react-dom/client';
import './tailwind.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';

import {AlertProvider} from './context/AlertContext';
import {AuthProvider} from './context/AuthContext';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import { MenuProvider } from './context/MenuContext';
import {MapBuilderProvider} from "./context/MapBuilderContext";
import {VisualizationProvider} from "./context/VisualizationContext";
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AlertProvider>
                    <AuthProvider>
                        <MenuProvider>
                            <MapBuilderProvider>
                                <VisualizationProvider>
                                    <App/>
                                </VisualizationProvider>
                            </MapBuilderProvider>
                        </MenuProvider>
                    </AuthProvider>
                </AlertProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function to
// log results (for example: reportWebVitals(console.log)) or send to an
// analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
