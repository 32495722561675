import {VisualizationContext} from "../../context/VisualizationContext";
import {Fragment, useContext, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import {RenderSample} from "../Maps/RenderSample";
import {FaTimes} from "react-icons/fa";
import {AlertContext} from "../../context/AlertContext";
import {SampleSizeControl} from "./Controls/SampleSizeControl";
import {SampleSelectionControl} from "./Controls/SampleSelectionControl";
import {GeneralStatControl} from "./Controls/GeneralStatControl";
import {ChartTypeControl} from "./Controls/ChartTypeControl";
import {HiddenStatControl} from "./Controls/HiddenStatControl";
import {XaxisControl} from "./Controls/XaxisControl";
export const VisualizationControlPanel = () => {
    const {
        showControls,
        setShowControls
    } = useContext(VisualizationContext);

    return (
        <Transition.Root show={showControls} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setShowControls}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    Visualization Control Panel
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() => setShowControls(false)}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6 flex flex-col gap-4">
                                            <p>Select the options below to build your visualized dataset.</p>
                                            {/* Control Components */}
                                            <XaxisControl />
                                            <ChartTypeControl />
                                            <GeneralStatControl />
                                            <SampleSizeControl />
                                            <HiddenStatControl />
                                            <SampleSelectionControl />
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
