import React from 'react'
import {FaTimes} from 'react-icons/fa'

export const AlertBar = ({alert, setAlert}) => {

    const getAlertBar = () => {
        if(alert.status >= 200 && alert.status <= 302) {
            return (
                <div
                    className=" bg-green-100 px-6 py-4 rounded-tl-md rounded-bl-md my-3 flex flex-row justify-between items-center capitalize">
                    <p className="text-green-600">{alert.message}</p>
                    <FaTimes
                        onClick={e => setAlert({
                            ...alert,
                            message: null,
                            status: null
                        })}
                        className="text-green-600 font-bold cursor-pointer text-2xl"/>
                </div>
            )
        }

        if(alert.status > 302 && alert.status < 800) {
            return (
                <div
                    className="w-full bg-red-100 px-6 py-4 rounded-tl-md rounded-bl-md my-3 flex flex-row justify-between items-center capitalize">
                    <p className="text-red-600">{alert.message}</p>
                    <FaTimes
                        onClick={e => setAlert({
                            ...alert,
                            message: null,
                            status: null
                        })}
                        className="text-red-600 font-bold cursor-pointer text-2xl"/>
                </div>
            )
        }

        return <div
            className="w-full bg-yellow-100 px-6 py-4 rounded-tl-md rounded-bl-md my-3 flex flex-row justify-between items-center capitalize">
            <p className="text-yellow-600">{alert.message}</p>
            <FaTimes
                onClick={e => setAlert({
                    ...alert,
                    message: null,
                    status: null
                })}
                className="text-yellow-600 font-bold cursor-pointer text-2xl"/>
        </div>

    }
    return (
        <div className="fixed top-2 right-0 w-1/3 z-50">
            {getAlertBar()}
        </div>
    )
}
