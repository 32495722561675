import {Fragment, useContext, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {formatTimestampToDate} from "../../hooks/formatTimestampToDate";
import {AuthContext} from "../../context/AuthContext";
import axios from "axios";
import {AlertContext} from "../../context/AlertContext";

export const FileMetadataOverlay = ({file, open, setOpen}) => {
    const {authenticated} = useContext(AuthContext);
    const [user, setUser] = useState(undefined)
    const [maps, setMaps] = useState([]);
    const {alert, setAlert} = useContext(AlertContext)

    useEffect( () => {
         getUserDetails()
         getAssociatedMaps()
    }, []);

    const getUserDetails = async () => {
        try {
            const res = await axios.get('/api/v1/users/details', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            });

            setUser(res.data);
        } catch (error) {
            setUser({email: 'Cannot get user details at this time'})
        }
    }

    const getAssociatedMaps = async () => {
        try {
            const res = await axios.get(`/api/v1/datamaps/${file._id}/maps`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            });

            setMaps(res.data.data)
        } catch (error) {
            setAlert({...alert, status: error.status, message: 'Cannot retrieve maps at this time.'})
        }
    }

    const renderMaps = () => {
        return (
            <Fragment>
                {
                    maps.map(map => {
                        return (
                            <span key={map._id} className={"text-xs p-2 rounded-full bg-gray-100"}>{map.name ?? 'Map ID: ' + map._id}</span>
                        )
                    })
                }
            </Fragment>
        )
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6 my-auto">
                                <div>
                                    <div className="px-4 sm:px-0">
                                        <h3 className="text-base font-semibold leading-7 text-gray-900">File Details</h3>
                                    </div>
                                    <div className="mt-6 border-t border-gray-100">
                                        <dl className="divide-y divide-gray-100">
                                            <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Uploaded at</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatTimestampToDate(file.uploadDate)}</dd>
                                            </div>
                                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Uploaded By</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{user ? user.email : null}</dd>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">File name</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{file.filename}</dd>
                                            </div>
                                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Is a conversion file?</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{file.metadata.conversion ? 'Yes' : 'No'}</dd>
                                            </div>
                                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Is a mapped file?</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{file.metadata.mapped ? 'Yes' : 'No'}</dd>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">File Size in KB</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{file.metadata.bytes}</dd>
                                            </div>
                                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">File Type</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 uppercase">{file.metadata.extension}</dd>
                                            </div>
                                            <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Associated Maps</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row gap-2 flex-wrap">{maps.length === 0 ? 'No Associated Maps' : renderMaps()}</dd>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                                                <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                                    Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                                                    qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                                                    pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>

    )
}
