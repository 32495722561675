import {formatTimestampToDate} from "../../../hooks/formatTimestampToDate";

export const UploadTimestamp = ({date}) => {
    return (
        <p className="whitespace-nowrap">
            Uploaded on{' '}
            <time dateTime={date}>{formatTimestampToDate(date)}</time>
        </p>
        )

}
