import {useUploadAction} from "../../../hooks/useUploadAction";
import React, {useContext} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {AlertContext} from "../../../context/AlertContext";
import axios from "axios";
import {ComingSoon} from "../../Badges/ComingSoon";

export const DeleteActionButton = ({styles, fileId, setRefreshTrigger}) => {
    const action = useUploadAction('delete');
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);

    if (!action) return null;

    /**
     * Delete a single file
     *
     * @param {string} id
     * @sets actingOn
     * @sets uploads
     * @sets alert
     * @return {void}
     */
    const deleteUpload = async () => {
        try {
            const res = await axios.delete(`/api/v1/files/${fileId}`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setRefreshTrigger(prev => prev + 1);
            setAlert({...alert, status: res.status, message: res.data.message})
        } catch (error) {
            setAlert({...alert, status: error.status, message: error.response.data.message})
        }
    }

    return (
        <button
            onClick={deleteUpload}
            className={styles
                ? styles
                : "rounded-md bg-gray-200 py-4 text-xs md:text-sm font-semibold text-ssg-800 shadow" +
                "-sm hover:bg-ssg-500 hover:text-white capitalize relative"}>{action.name}
            <ComingSoon available={action.available} />
        </button>
    )
}
