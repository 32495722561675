import React, { useState, createContext, useEffect } from "react";
import { AlertBar } from "../components/alerts/AlertBar";
import { MultiAlert } from "../components/alerts/MultiAlert";
import { getCookie } from "../hooks/getCookie";
import axios from "axios";

export const AlertContext = createContext();

export const AlertProvider = ({children}) => {
    const [alert, setAlert] = useState({
        alerts: [],
        status: null,
        message: null,
    })

    if(alert.status !== null) {
        setTimeout(() => {
            setAlert({
                status: null,
                message: null,
                alerts: []
            })
        }, 2500);
    }

    useEffect(() => {
        if(alert.alerts.length > 0) {
            alert.alerts.map(alertItem => {
                return validateAlertMessage(alertItem.message)
            })
        } else {
            return validateAlertMessage(alert.message)
        }
    }, [alert.alerts, alert.message])

    /**
     * Validates the alert message and triggers an immediate logout if the message indicates an invalid token.
     * 
     * @param {string} message - The alert message to validate.
     */
    const validateAlertMessage = (message) => {
        if(message === 'Invalid token' || message === 'Invalid authentication token.') {
            immediateLogout()
        }
    }

    /**
     * Immediately logs the user out by invalidating the authentication token and redirecting to the home page.
     */
    const immediateLogout = async () => {
        const token = getCookie('auth')

        const res = await axios.post('/api/v1/auth/logout', {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        })
    
        if(res.status === 204) {
            document.cookie = 'auth=false; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
            window.location.href = '/'
        }
    }

    return (
        <AlertContext.Provider value={{alert, setAlert}}>
            {alert.message !== null && alert.status !== null ? 
                <AlertBar setAlert={setAlert} alert={alert}/>
                : null}

                {
                    alert.alerts?.length > 0 ? 
                        <MultiAlert />
                    : null
                }
            {children}
        </AlertContext.Provider>
    )
}