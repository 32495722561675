import {GeneralOverlay} from "../../Overlays/GeneralOverlay";
import {FaSpinner} from "react-icons/fa";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";

export const VisualizationLoading = () => {
    const {reset, loading} = useContext(VisualizationContext);

    return (
        <GeneralOverlay open={loading} setOpen={null}>
            <div className={'bg-white max-w-xl w-full max-h-80 h-full px-4 py-10 rounded-lg flex flex-col'}>
                <div className={'mb-4'}>
                    <h2 className={'text-2xl font-semibold'}>Your Visualization Is Loading</h2>
                    <p>This screen will automatically close when the data has completed processing. If you wish to cancel the process, please click the button below.</p>
                </div>
                <FaSpinner className={'spin m-auto text-4xl'}/>
                <Link to={'/visualize'}
                      onClick={() => reset()}>
                    <button type={'button'} className={'relative bg-ssg w-full px-6 py-3 rounded-lg text-white font-semibold tracking-wide hover:bg-ssg-600 cursor-pointer mt-4'}>Return To Dashboard</button>
                </Link>
            </div>
        </GeneralOverlay>
    )
}
