import React, { useContext} from "react";
import {PlusIcon} from "@heroicons/react/20/solid";
import {MapBuilderContext} from "../../context/MapBuilderContext";
import axios from "axios";
import {AuthContext} from "../../context/AuthContext";
import {AlertContext} from "../../context/AlertContext";

export const AddBuildablePropertyButton = () => {
    const {map, setMap, isLoading, setIsLoading} = useContext(MapBuilderContext);
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);

    /**
     * Insert a buildable property with empty schema via API
     */
    const addBuildableProperty = async () => {
        setIsLoading(true)
        try {
            const res = await axios.post('/api/v1/datamaps/method/buildable', {
                source: map.source,
                mutation: map.mutation,
                path: 'Placeholder',
                value: null
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setMap({...map, mutation: res.data.modified})
        } catch (error) {
            setAlert({...alert, message: error.response.data.message, status: error.response.data.status})
        }
        setIsLoading(false)
    }

    return (
        <div className={"my-3"}>
            <button onClick={addBuildableProperty} className={"flex flex-row items-center gap-2 rounded-full border border-ssg hover:bg-ssg hover:text-white duration-200 pr-4"}>
                <div className="rounded-full bg-ssg-500  p-1 text-white shadow-sm hover:bg-ssg-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <span className={"font-light text-sm"}>Create new field</span>
            </button>
        </div>
    )
}
