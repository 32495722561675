import {useState} from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const MapPropertySearch = ({keys, handleKeySelect}) => {
    const [query, setQuery] = useState('')

    const filteredKeys =
        query === ''
            ? Object.keys(keys)
            : Object.keys(keys).filter((key) => {
                return key.toLowerCase().includes(query.toLowerCase());
            });

    const processSelect = (selection) => {
        const splitSelection = selection.split(':');
        setQuery(splitSelection[0]);
        handleKeySelect(selection)
    }

    return (
        <Combobox as="div" value={query} onChange={(selected) => processSelect(selected)}>
            <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Search Data Properties</Combobox.Label>
            <div className="relative mt-2">
                <Combobox.Input
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(event) => {
                        if(event.target.value !== '' && event.target.value !== undefined) {
                            setQuery(event.target.value)
                        } else {
                            setQuery('')
                            handleKeySelect(undefined)
                        }

                    }}
                    displayValue={(key) => key}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </Combobox.Button>

                {filteredKeys.length > 0 && query !== '' && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredKeys.map((key) => (
                            <Combobox.Option
                                key={Math.random()}
                                value={`${key}: ${keys[key]}`}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-8 pr-4',
                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames('block truncate', selected && 'font-semibold')}>{key}</span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                    active ? 'text-white' : 'text-indigo-600'
                                                )}
                                            >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    )
}
