import React, { useState } from 'react';
import {HiQuestionMarkCircle} from "react-icons/hi";

export const QuestionTooltip = ({content}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="relative block sm:inline-block text-justify">
            <HiQuestionMarkCircle
                onMouseEnter={() => setShowTooltip(true)}
                 onMouseLeave={() => setShowTooltip(false)}
                className={'text-lg text-gray-400 hover:text-gray-600 font-bold cursor-pointer'} />

            {showTooltip && (
                <div className="absolute top-full right-full sm:left-full ml-3 sm:top-1/2 sm:transform sm:-translate-y-1/2 p-3 bg-white text-gray-400 rounded-lg shadow-md text-sm w-72">
                    {content}
                </div>
            )}

        </div>
    );
}
