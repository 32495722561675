/**
 * Retrieves the value of the 'name' cookie from the browser's document.
 *
 * @returns {string|null} - The value of the 'name' cookie if it exists, otherwise null.
 */
export const getCookie = (name) => {
    const auth = `${name}=`;
    const ca = document.cookie.split(';');
    
    for(let i=0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(auth) === 0) return c.substring(auth.length, c.length);
    }
    
    return null;
}