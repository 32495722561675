import {createContext, useEffect, useState} from "react";

export const MenuContext = createContext();

export const MenuProvider = ({children}) => {
    const [menuOpen,
        setMenuOpen] = useState(true)

    useEffect(() => {
        // Function to check window size and update menuOpen state
        const checkWindowSize = () => {
            if (window.innerWidth <= 768) { // Assuming 768px as the breakpoint for smaller devices
                setMenuOpen(false);
            }
        };

        // Initial check
        checkWindowSize();

        // Add event listener for window resize
        window.addEventListener('resize', checkWindowSize);

        // Cleanup - remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', checkWindowSize);
        };
    }, [])

    return (
        <MenuContext.Provider
            value={{
            menuOpen,
            setMenuOpen
        }}>
            {children}
        </MenuContext.Provider>
    )
}