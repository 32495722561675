import {RenderSample} from "../../Maps/RenderSample";
import {VisualizationStepButtons} from "../VisualizationStepButtons";
import {GeneralOverlay} from "../../Overlays/GeneralOverlay";
import {useContext, useEffect, useState} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";
import {FaTimes} from "react-icons/fa";

export const VisualizationPropertySelector = () => {
    const {sample, selections, setSelections} = useContext(VisualizationContext);
    const [nextable, setNextable] = useState(false)

    useEffect(() => {
        Object.keys(selections).length > 0 ? setNextable(true) : setNextable(false)
    }, [selections]);
    /**
     * Add property
     */
    const setPropertiesToVisualize = (displayKey, path) => {
        console.log(path)
        const newProps = {...selections};

        if(!newProps.hasOwnProperty(displayKey)) {
            newProps[displayKey] = path
        }

        setSelections(newProps)
    }

    /**
     * Remove selected property
     */
    const removeProperty = key => {
        const newProps = {...selections}
        delete newProps[key]
        setSelections(newProps)
    }

    return (
        <GeneralOverlay open={true} setOpen={null}>
            <div className={'w-full max-w-5xl p-8'}>
                {Object.keys(selections).length > 0 ?
                        <div className={'bg-white p-3 rounded-lg mb-3 text-left'}>
                            <h2 className={'text-md font-medium mb-2'}>Selected</h2>
                            <div className={'flex flex-row gap-3 flex-wrap'}>
                                {Object.entries(selections).map(([key, value]) => {
                                    return (
                                        <button key={Math.random()} onClick={() => removeProperty(key)} className={'text-xs bg-gray-100 p-2 rounded flex flex-row items-center gap-2'}>{key} <FaTimes className={'text-red-500'}/></button>
                                    )
                                })}
                            </div>
                        </div>
                    : null}
                <div className={'bg-white p-4 rounded-lg text-left'}>
                    <div className={'mb-5 pl-4'}>
                        <h2 className={'font-medium font-md'}>Select Visualization Properties</h2>
                        <p className={'text-sm w-1/2'}>The data below is a sample representation of a single object or "row" of this data source. You will be able to change these while visualizing data.</p>
                    </div>
                    <RenderSample data={sample} handleClick={setPropertiesToVisualize}/>
                </div>
                <VisualizationStepButtons nextable={nextable} />
            </div>
        </GeneralOverlay>
    )
}
