import {
HomeIcon,
ArrowUpOnSquareIcon,
CircleStackIcon,
ChartPieIcon,
TableCellsIcon, } from '@heroicons/react/24/outline';

export const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon },
  { name: 'Data Sources', href: '/sources', icon: ArrowUpOnSquareIcon },
  { name: 'Map', href: '/map', icon: CircleStackIcon },
  { name: 'Visualize', href: '/visualize', icon: ChartPieIcon },
  { name: 'Connect', href: '/connect', icon: TableCellsIcon },
];

export const userNavigation = [
    { name: 'Profile', href: '#' }
];
