import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useState, useEffect} from "react";

/**
 * Get files for authenticated user
 *
 * @param {string} authToken - authentication token for user
 * @param {number} refresh
 * @return {isLoading: boolean, data: unknown, error: unknown}
 *
 */
export const useGetFiles = (authToken, refresh) => {
    const {
        data,
        error,
        isLoading,
        refetch
    } = useQuery(
        ['getFiles'],
        async () => {
            try {
                const res = await axios.get('/api/v1/files', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                });
                return res.data.data;
            } catch (error) {
                throw error;
            }
        },
        {
            enabled: false
        }
    );

    useEffect(() => {
        refetch();
    }, [refresh, refetch]);

    return { data, error, isLoading };
}

/**
 * Get single for authenticated user
 *
 * @param {string} authToken - authentication token for user
 * @param {string} fileId - File Id
 * @return AxiosResponse<any>
 *
 */
export const useGetFile = (authToken, fileId) => {
    const { data, error, isLoading } = useQuery(
        ['getFile'],
        async () => {
            try {
                const res = await axios.get(`/api/v1/files/${fileId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                });

                return res.data;
            } catch (error) {
                throw error;
            }
        }
    );

    return { data, error, isLoading };
}

/**
 * Get single for authenticated user
 *
 * @param {string} authToken - authentication token for user
 * @param {string} fileId - File Id
 * @return AxiosResponse<any>
 *
 */
export const useGetFileSample = (authToken, fileId) => {
    const { data, error, isLoading } = useQuery(
        ['getFileSample'],
        async () => {
            try {
                const res = await axios.get(`/api/v1/files/${fileId}/sample`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                });
                return res.data;
            } catch (error) {
                throw error;
            }
        }
    );

    return { data, error, isLoading };
}

/**
 * Create new conversion file
 *
 * @param {string} authToken
 * @param {object} conversionData = {
 *     id,
 *     action,
 *     option
 * }
 * @return {Promise<AxiosResponse>}
 */
export const useNewConversionFile = (authToken, conversionData) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const makeNewConversionFile = async () => {
            setLoading(true);
            try {
                const res = await axios.put(`/api/v1/files/${conversionData.id}/conversion`, {
                    action: conversionData.action,
                    option: conversionData.option
                }, {
                    responseType: 'blob', // Important: set the response type to 'blob'
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setResponse(res);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        makeNewConversionFile();
    }, [authToken, conversionData]);

    return { response, error, loading };
};

/**
 * Update a single file name
 *
 * @param {string} authToken
 * @param {object} upload - {
 *     id,
 *     name
 * }
 * @return {Promise<AxiosResponse>}
 */
export const useUpdateFileName = async (authToken, upload)=> {
    try {
        return await axios.put(`/api/v1/files/${upload._id}/name`, upload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`
            }
        })
    } catch (error) {
        throw error;
    }
}

/**
 * Delete file
 *
 * @param {string} authToken
 * @param {string} id
 * @return {Promise<AxiosResponse>}
 */
export const useDeleteFile = async (authToken, id) => {
    try {
        return await axios.delete(`/api/v1/files/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`
            }
        })
    } catch (error) {
        throw error;
    }
}
