import {FaTimes} from 'react-icons/fa'

export const UploadedFiles = ({uploads, setUploads}) => {
    /**
     * Removes the specified file from the current list of files and updates the state.
     *
     * @param {Object} uploaded - The file object to be removed from the current list.
     */
    const removeFile = (uploadIndex, file) => {
        let newUploads = [...uploads]
        const upload = newUploads[uploadIndex];
        const files = upload.files;
        const updatedFiles = files.filter(item => item !== file);
        upload.files = updatedFiles || [];

        if (updatedFiles.length === 0) {
            newUploads = newUploads.filter(item => item !== upload);
        } else {
            newUploads[uploadIndex] = upload
        }

        setUploads(newUploads)
    }

    return (
        <div
            className='w-full grid grid-cols-1 sm:grid-flow-row sm:grid-cols-12 items-center align-middle my-3 space-x-3'>
            <div className='col-span-2'>
                <h2 className="text-sm font-medium text-gray-900 mb-2 my-auto">Uploaded Data Sources:</h2>
            </div>

            <div
                className="grid space-y-2 sm:grid-flow-row sm:space-x-4 sm:grid-cols-3 md:grid-cols-6 col-span-10">
                {uploads.map((uploaded, uploadIndex) => {
                    return uploaded
                        .files
                        .map((file, fileIndex) => {
                            return (
                                <button
                                    key={fileIndex}
                                    onClick={e => removeFile(uploadIndex, file)}
                                    className='bg-gray-200 px-3 py-1 text-xs rounded-lg flex flex-row justify-between items-center w-full my-auto'>
                                    {file.name}
                                    <FaTimes
                                        className='hover:text-red-500 cursor-pointer text-md'
                                        onClick={e => removeFile(uploadIndex, file)}/>
                                </button>
                            )
                        })

                })}
            </div>
        </div>
    )
}
