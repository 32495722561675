import {Fragment, useContext, useState} from "react";
import {AlertContext} from "../../context/AlertContext";
import {MapBuilderContext} from "../../context/MapBuilderContext";
import {AuthContext} from "../../context/AuthContext";
import axios from "axios";
import {FaTimes} from "react-icons/fa";
import {MutationEmptyState} from "./MutationEmptyState";
import {BsDatabaseUp} from "react-icons/bs";
import {GeneralOverlay} from "../Overlays/GeneralOverlay";
import {ConfirmationOverlay} from "../Overlays/ConfirmationOverlay";

function classNames(...classes) {
    return classes
        .filter(Boolean)
        .join(' ');
}

export const MutationBuilder = ({  mutation, setMutation, handleClick, parentKey = '', isLast = true }) => {
    const {alert, setAlert} = useContext(AlertContext)
    const {map, setMap, isLoading, setIsLoading} = useContext(MapBuilderContext);
    const {authenticated} = useContext(AuthContext);

    const [editKey, setEditKey] = useState('');
    const [newKey, setNewKey] = useState('');
    const [dragPath, setDragPath] = useState('')
    const [draggingChild, setDraggingChild] = useState(false)
    const [removalConfirmation, setRemovalConfirmation] = useState(false)
    const [removingPath, setRemovingPath] = useState(undefined);
    if(!mutation) return;

    const keys = Object.keys(mutation);

    /**
     * Formats the placeholder by removing the '_SYS_' suffix from the key.
     * @param {string} key - The key string containing '_SYS_'.
     * @returns {string} - The formatted string without the '_SYS_' suffix.
     */
    const formatPlaceholder = (key) => {
        const phArray = key.split('_SYS_');
        return phArray[0]
    }

    /**
     * Handles the setting of a new key in the mutation object.
     * It replaces the old key with the new key and updates the state.
     * @param path
     */
    const handleSetNewKey = async (path) => {
        setIsLoading(true)
        try {
            const res = await axios.post('/api/v1/datamaps/method/key', {
                source: map.source,
                mutation: map.mutation,
                path: path,
                value: newKey
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setMap({...map, mutation: res.data.modified})
            setIsLoading(!isLoading)
        } catch (error) {
            setAlert({...alert, message: error.response.data.message, status: error.response.data.status})
        }
        setIsLoading(false)
    }

    /**
     * Handles the drop event on an item.
     * It sends a POST request to add a new mutation and updates the state with the response.
     * @param {Event} e - The drop event object.
     */
    const handleDropOnItem = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const [key, value] = getTransferData(e);

        try {
            const res = await axios.post('/api/v1/datamaps/method/add', {
                source: map.source,
                mutation: map.mutation,
                path: `${dragPath}.${key}`,
                value: value
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setMap({...map, mutation: res.data.modified})
        } catch (error) {
            setAlert({...alert, message: error.response.data.message, status: error.response.data.status})

        }
        setIsLoading(false)
        setDragPath('')
    }


    /**
     * Extracts and returns the transfer data from the event object.
     * @param {Event} e - The event object containing the transfer data.
     * @returns {Array} - An array containing the key and the trimmed value extracted from the event's dataTransfer.
     */
    const getTransferData = (e) => {
        const data = e.dataTransfer.getData('text/plain').split(':');
        let isChild;
        if(data.includes('child')) {
            isChild = true;
        }

        return [data[0], data[1].trim(), isChild]
    }

    /**
     * Handles the drag start event and sets the drag data.
     * The drag data is set as a string in the format 'key: path'.
     *
     * @param {DragEvent} e - The drag start event object.
     * @param {string} key - The key to be set as drag data.
     * @param {string} path - The path to be set as drag data.
     */
    const handleDragStart = (e, key, path) => {
        const property = `${key}: ${path}:child`;
        setDraggingChild(!draggingChild);
        e.dataTransfer.setData('text/plain', property);
    };

    const addSchema = async (e, path) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const res = await axios.post('/api/v1/datamaps/method/schema', {
                source: map.source,
                mutation: map.mutation,
                path,
                value: null

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setMap({...map, mutation: res.data.modified})
        } catch (error) {
            setAlert({...alert, message: error.response.data.message, status: error.response.data.status})

        }
        setIsLoading(false)
        setDragPath('')
    }

    /**
     * Handles the removal of a property in the mutation object.
     * It replaces the old key with the new key and updates the state.
     * @param path
     */
    const removeProperty = async (path) => {
        setIsLoading(true)
        try {
            const res = await axios.post('/api/v1/datamaps/method/remove', {
                source: map.source,
                mutation: map.mutation,
                path: path,
                value: null
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            setMap({...map, mutation: res.data.modified})
            setEditKey('')
            setNewKey('')
            setDragPath('')
        } catch (error) {
            setAlert({...alert, message: error.response.data.message, status: error.response.data.status})
        }

        setIsLoading(false)
    }

    /**
     * Sets path to the property user is removing
     * Opens Confirmation paenl
     */
    const handleRemoveIconClick = (path) => {
        setRemovingPath(path)
        setRemovalConfirmation(true)
    }

    /**
     * Awaits API call to remove property by path
     * Resets removing path and closes confirmation panel
     */
    const handleRemovalConfirmation = async () => {
        await removeProperty(removingPath)
        handleRemovalCancel()
    }

    const handleRemovalCancel = () => {
        setRemovingPath(undefined)
        setRemovalConfirmation(false)
    }

    return (
        <div className="pretty-map">
            {
                Object.keys(map.mutation).length > 0 ?
                    keys.map((key, index) => {
                        // Skip rendering numeric keys (array indices)
                        if (Array.isArray(mutation) && !isNaN(key)) {
                            return <MutationBuilder key={index} mutation={mutation[key]} setMutation={setMutation} handleClick={handleClick} parentKey={parentKey} isLast={index === mutation.length - 1} />;
                        }

                        const path = parentKey ? `${parentKey}.${key}` : key;
                        const isLastKey = index === keys.length - 1;

                        if(key === 'path' || key === 'type') {
                            return;
                        }
                        return (
                            <div
                                key={path}
                                className={`node ${isLastKey ? 'last' : ''}`}>
                                <div
                                    className={classNames(dragPath === path ? 'bg-gray-100 text-gray-400 font-bold' : '', "key p-2 border rounded-lg my-1 flex flex-row justify-between items-center")}
                                    id={`${parentKey}.${key}`}
                                    onDrop={(e) => handleDropOnItem(e)}
                                    onDragOver={e  => e.preventDefault()}
                                    onDragEnter={_e  => setDragPath(path)}
                                    onDragExit={_e  => setDragPath('')}
                                    onDragLeave={_e  => setDragPath('')}
                                    onDragStart={(e) => handleDragStart(e, key, path)}
                                    draggable={!isLoading && key !== 'path' && key !== 'type'}
                                >
                                    {editKey === key ?
                                        <input
                                            type="text"
                                            placeholder={'Enter property name'}
                                            className={"block w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"}
                                            name={key}
                                            onChange={e =>  setNewKey(e.target.value) }
                                            autoFocus={true}
                                            disabled={isLoading && key !== 'path' && key !== 'type'}
                                            onBlur={e =>  e.target.value !== '' ? handleSetNewKey(path) : setEditKey('')}
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    if(e.target.value !== '') {
                                                        handleSetNewKey(path);
                                                    } else {
                                                        setEditKey('')
                                                    }

                                                }
                                            }}
                                        />  : <div
                                                title={isLoading || key === 'path' || key === 'type'  || key === 'schema' ? '' : "Click to change name"}
                                                onClick={() => isLoading || key === 'path' || key === 'type'  || key === 'schema' ? null : setEditKey(key)}
                                                className={classNames(isLoading || key === 'path' || key === 'type'  || key === 'schema' ? '' : 'hover:bg-gray-100', 'px-3 ml-1 rounded-md w-full')}>
                                            {formatPlaceholder(key)}
                                    </div>}

                                    {/*Data controlls*/}
                                    <div className={"flex items-center flex-row"}>
                                        {
                                            key !== 'path' && key !== 'type' && key !== 'schema'  ?
                                                <button className={"text-xl p-2 rounded-md font-semibold hover:bg-gray-100"} title={'Add Schema'} onClick={e => addSchema(e, path)}>
                                                    <BsDatabaseUp />
                                                </button> : null
                                        }

                                        {
                                            key !== 'path' && key !== 'type' ?
                                                <button className={"text-xl p-2 rounded-md font-semibold hover:bg-gray-100"} title={'Remove Property'} onClick={_e => handleRemoveIconClick(path)}>
                                                    <FaTimes
                                                        className={"text-red-500 text-lg cursor-pointer"}
                                                        title={'Remove Property'}

                                                    />
                                                </button>
                                                : null
                                        }
                                    </div>


                                </div>
                                {typeof mutation[key] === 'object' && mutation[key] !== null ? (
                                    <MutationBuilder key={index} mutation={mutation[key]} setMutation={setMutation} handleClick={handleClick} parentKey={path} isLast={isLastKey} />
                                ) : null}
                            </div>
                        );
                    })
                    : <MutationEmptyState />
            }
            {removalConfirmation === true ?
                <ConfirmationOverlay open={removalConfirmation} setOpen={setRemovalConfirmation} options={{
                    title: 'Confirm removing property',
                    text: 'By removing this property you also remove any schema underneath it as children. This action cannot be undone.',
                    confirm: () => handleRemovalConfirmation(),
                    cancel: () => handleRemovalCancel()
                }} /> :
                    null}
        </div>
    );
};
