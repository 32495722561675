import {Fragment} from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

/**
 * Handles file actions with options: 
 * 
 * if data
 */
export const OptinalFileActionButton = ({action, styles, dataDependancy, handleClick, showBadge}) => {
  return (
    <Menu as="div" className="relative inline-block text-left z-40">
        <div>
            <Menu.Button className={styles ? styles : "inline-flex w-full justify-center gap-x-1.5 px-3 rounded-md bg-gray-200 py-4 text-xs md:text-sm font-semibold text-ssg-800 shadow-sm hover:bg-ssg-500 hover:text-white capitalize"}>
            {action.name}
            {showBadge ?
                !action.available
                ? <span
                        className="inline-flex items-center gap-x-1.5 rounded-md bg-ssg-400 px-1.5 py-0.5 text-xs text-ssg-600 absolute -top-2 -right-2">
                        <svg className="h-1.5 w-1.5 fill-ssg-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3}/>
                        </svg>
                        Coming Soon
                    </span>
                : null
                : null}
            <ChevronDownIcon className="-mr-1 h-5 w-5 font-semibold" aria-hidden="true" />
            </Menu.Button>
        </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="flex flex-col space-y-2 py-3 px-2">
            <h2 className='text-xs text-gray-500 font-medium'>{action?.optionsLabel}</h2>
                {action.options.map(option => {
                    return (
                        <Menu.Item key={Math.random()}>
                            <button
                            onClick={e => dataDependancy !== undefined ? handleClick(dataDependancy, action.name, option) : handleClick(action.name, option)}
                            className={'bg-gray-100 text-gray-900 block px-4 py-2 text-sm w-full  rounded-lg hover:bg-gray-200 uppercase'}
                            >
                            {option}
                            </button>
                        </Menu.Item>
                    )
                })}
            </div>
            </Menu.Items>
        </Transition>
    </Menu>
  )
}
