import {ComingSoon} from "../../Badges/ComingSoon";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {AlertContext} from "../../../context/AlertContext";
import {useGetFile} from "../../../hooks/useFiles";
import {useUploadAction} from "../../../hooks/useUploadAction";
import {MenuContext} from "../../../context/MenuContext";
import {VisualizationContext} from "../../../context/VisualizationContext";

export const VisualizeActionButton = ({styles, fileId}) => {
    const {authenticated} = useContext(AuthContext);
    const {reset} = useContext(VisualizationContext);
    const {alert, setAlert} = useContext(AlertContext);
    const { data, error } = useGetFile(authenticated, fileId)
    const action = useUploadAction('visualize');
    const {setMenuOpen} = useContext(MenuContext)

    const [file, setFile] = useState(undefined)

    useEffect(() => {
        if (data) {
            setFile(data);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setAlert({ ...alert, status: 500, message: 'Something went wrong. Cannot retrieve your uploads...' });
        }
    }, [alert, setAlert, error]);

    if (!action) return null;

    const handleClick = () => {
        setMenuOpen(false)
        reset()

        window.location = `/visualize/${file._id}`
    }
    return (
        <Fragment>
            {
                !file ? null : <button
                    onClick={e => handleClick()}
                    className={styles
                        ? styles
                        : "rounded-md bg-gray-200 py-4 text-xs md:text-sm font-semibold text-ssg-800 shadow" +
                        "-sm hover:bg-ssg-500 hover:text-white capitalize relative"}>{action.name}
                    <ComingSoon available={action.available} />
                </button>
            }

        </Fragment>
    )
}
