import React, {Fragment, useContext} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import axios from "axios";
import {AuthContext} from "../../../context/AuthContext";
import {AlertContext} from "../../../context/AlertContext";
import {useUploadAction} from "../../../hooks/useUploadAction";
import {ComingSoon} from "../../Badges/ComingSoon";
export const ConvertActionButton = ({styles, fileId}) => {
    const action = useUploadAction('convert');
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);


    if (!action) return null;

    /**
     * Make new conversion of file from dialog box
     *
     * @param {string} id
     * @param {string} action
     * @param {string} option
     */
    const makeNewConversion = async (id, action, option) => {
        try {
            const res = await axios.put(`/api/v1/files/${id}/conversion`, {action, option}, {
                responseType: 'blob', // Important: set the response type to 'blob'
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })
            initiateBrowserDownload(res)
        } catch (error) {
            setAlert({...alert, status: error.status, message: error.response.data.message})
        }
    }

    /**
     * Initiate browser download
     *
     * @param {AxiosResponse} res
     * @return {void}
     */
    const initiateBrowserDownload =  (res) => {
        const filename = getDownloadFilename(res)
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);

        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    /**
     * Get file name from response headers
     *
     * @param {object} res
     * @return {string}
     */
    const getDownloadFilename = (res) => {
        const contentDisposition = res.headers['content-disposition'];
        let filename = 'downloaded_file'; // default filename if not provided
        if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    }

    return (
        <Menu as="div" className="relative inline-block text-left z-40">
            <div>
                <Menu.Button className={styles ? styles : "inline-flex w-full justify-center gap-x-1.5 px-3 rounded-md bg-gray-200 py-4 text-xs md:text-sm font-semibold text-ssg-800 shadow-sm hover:bg-ssg-500 hover:text-white capitalize"}>
                    {action.name}
                    <ComingSoon available={action.available} />
                    <ChevronDownIcon className="-mr-1 h-5 w-5 font-semibold" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="flex flex-col space-y-2 py-3 px-2">
                        <h2 className='text-xs text-gray-500 font-medium'>{action?.optionsLabel}</h2>
                        {action.options.map(option => {
                            return (
                                <Menu.Item key={Math.random()}>
                                    <button
                                        onClick={e => makeNewConversion(fileId, action, option)}
                                        className={'bg-gray-100 text-gray-900 block px-4 py-2 text-sm w-full  rounded-lg hover:bg-gray-200 uppercase'}
                                    >
                                        {option}
                                    </button>
                                </Menu.Item>
                            )
                        })}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
