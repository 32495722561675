import { useContext, useState } from "react"
import {Logo} from "../components/Logo"
import axios from 'axios'
import { AlertContext } from "../context/AlertContext"
import {Link} from "react-router-dom";

export const Login = () => {
    const {alert, setAlert} = useContext(AlertContext)
    const [auth, setAuth] = useState({
        email: '',
        password: ''
    })

    /**
     * Handles the login form submission. It validates the email address,
     * attempts to log in using the provided credentials, and sets alert
     * messages or redirects based on the result.
     *
     * @param {Event} e - The event object from the form submission.
     */
    const handleLoginSubmission = async (e) => {
        e.preventDefault();

        if(isValidEmail(auth.email) === true) {
            try {
                const res = await axios.post('/api/v1/auth/login', auth, {
                    headers: {
                        'Content-Type': 'application/json'
                   }
                })

                setAlert({...alert, status: 200, message: 'Success'})
                setAuthCookie(res.data)
                setTimeout(() => {
                    redirectIfSuccessful()
                }, 400);
            } catch (error) {
                setAlert({...alert, status: error.status, message: error.response.data.message})
            }
        } else {
            setAlert({...alert, status: 400, message: 'Invalid credentials'})
        }
    }

    /**
     * Validates the provided email address against a common pattern.
     *
     * @param {string} email - The email address to validate.
     * @returns {boolean} True if the email address is valid, false otherwise.
     */
    const isValidEmail = (email) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }

    /**
     * Redirects the user to the dashboard page if login was successful.
     * This function assumes that it will only be called upon successful login.
     */
    const redirectIfSuccessful = () => {
        window.location.href = '/dashboard'
    }

    /**
     * Sets an authentication cookie to indicate the user is authenticated.
     */
    const setAuthCookie = (token) => {
        document.cookie = `auth=${token}`;
    }

    return (
        <div className="flex min-h-full flex-1 bg-gray-900">
            <div
                className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <Logo/>
                        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-50">
                            Sign in to your account
                        </h2>
                        <p className="text-sm font-light leading-9 text-gray-50">Don't have an account?
                            <Link to={"/register"}>
                                <span className={'underline ml-2'}>Register Here</span>
                            </Link>
                        </p>
                    </div>

                    <div className="mt-10">
                        <div>
                            <form onSubmit={handleLoginSubmission} className="space-y-6">
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-400">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            onChange={e => setAuth({...auth, [e.target.name]: e.target.value})}
                                            autoComplete="email"
                                            placeholder="Email Address"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <div>
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium leading-6 text-gray-400">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            onChange={e => setAuth({...auth, [e.target.name]: e.target.value})}
                                            autoComplete="current-password"
                                            placeholder="Password"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            id="remember-me"
                                            name="remember-me"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-gray-400 focus:ring-gray-400"/>
                                        <label
                                            htmlFor="remember-me"
                                            className="ml-3 block text-sm leading-6 text-gray-400">
                                            Remember me
                                        </label>
                                    </div>

                                    <div className="text-sm leading-6">
                                        <button type="button" className="font-semibold text-ssg-600 hover:text-ssg-500">
                                            Forgot password?
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        onClick={handleLoginSubmission}
                                        className="flex w-full justify-center rounded-md bg-ssg-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-ssg-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ssg-600">
                                        Sign in
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""/>
            </div>
        </div>
    )
}
