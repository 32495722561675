import {useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";
import {ControlLabel} from "./ControlLabel";
import {ControlDetail} from "./ControlDetail";
import {AlertContext} from "../../../context/AlertContext";

export const SampleSizeControl = () => {
    const {
        setSampleSize,
        sampleSize
    } = useContext(VisualizationContext);

    const {alert, setAlert} = useContext(AlertContext)

    const handleSampleSizeUpdate = value => {
        if(value >= 2500) {
            setAlert({...alert, status: 999, message: 'Sample sizes over 2500 records may hinder browser performance.'})
        }

        setSampleSize(value)
    }

    return (
        <div className={'bg-gray-100 p-3 rounded-lg'}>
            <ControlLabel label={'Sample Size'}/>
            <ControlDetail detail={'By default the sample size is 100, you can change this number then press enter or click outside of the input to make the changes.'} />
            <div className={'flex flex-col gap-2 my-3'}>
                <input type="number" className={'rounded-lg capitalize'} onBlur={e => handleSampleSizeUpdate(e.target.value)} onKeyPress={e => {
                    if (e.key === 'Enter') {
                        handleSampleSizeUpdate(e.target.value);
                    }
                }} defaultValue={sampleSize}/>
            </div>
        </div>
    )
}
