import {ControlLabel} from "./ControlLabel";
import {ControlDetail} from "./ControlDetail";
import {useContext} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";

export const ChartTypeControl = () => {
    const {
        availableCharts,
        chartType,
        setChartType
    } = useContext(VisualizationContext);

    return (
        <div className={'bg-gray-100 p-3 rounded-lg'}>
            <ControlLabel label={'Chart Type'}/>
            <ControlDetail detail={'Select a different chart type to improve visualization clarity.'} />
            <div className={'flex flex-col gap-2 my-3'}>
                <select onChange={e => setChartType(e.target.value)} className={'rounded-lg capitalize'}>
                    {chartType ? <option value={chartType}>{chartType}</option> : <option>Select An Option</option>}
                    {availableCharts.map(item => {
                        if(item.type === chartType) return;

                        return (
                            <option key={Math.random().toString()} value={item.type}>{item.type}</option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}
