import axios from "axios";
import {useContext, useState} from "react";
import { AlertContext } from "../../context/AlertContext";
import { AuthContext } from "../../context/AuthContext";
import {GeneralOverlay} from "../Overlays/GeneralOverlay";
import {FaSpinner} from "react-icons/fa";

export const UploadConfirmationControls = ({uploads, setUploads, refreshTrigger, setRefreshTrigger, cancel}) => {
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false)

    /**
     * Prepares the form data for the upload request.
     * @returns {uploadData} - The prepared json data with base64 encoded files.
     */
    const prepareData = async () => {
        let uploadData = [];

        for (let upload of uploads) {
            let filesData = [];
            for (let file of upload.files) {
                const fileContent = await readFileAsBase64(file);
                filesData.push({ // Mimicks the IFile interface in API.
                    originalname: file.name,
                    encoding: 'base64',
                    mimetype: file.type,
                    buffer: fileContent, // will be converted to buffer on server side
                    size: file.size
                });
            }
            uploadData.push({
                ...upload,
                files: filesData
            });
        }

        return uploadData;
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                // Remove the "data:*/*;base64," part from the result to get only the base64 string
                const base64String = event.target.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    /**
     * Handles the upload request for a given file, sending it to a server endpoint.
     * If successful, sets an alert state with the server's response.
     * If an error occurs, captures the error and updates the alert state accordingly.
     */
    const handleUploadRequest = async() => {
        setLoading(true)
        try {
            const data = await prepareData();
            const response = await axios.post('/api/v1/files', {uploads: data}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            });

            setRefreshTrigger(prev => prev + 1);
            handleSuccessResponse(response);
        } catch (error) {
            handleErrorResponse(error);
        }
    };

    /**
     * Handles successful upload response.
     * @param {Object} response - The file data that was uploaded.
     */
    const handleSuccessResponse = (response) => {
        const files = response.data.data;

        if (Array.isArray(files)) {
            const newAlerts = files.map(_file => ({status: response.status, message: `Success`}));

            setAlert({
                ...alert,
                alerts: newAlerts
            });
        } else {
            setAlert({
                ...alert,
                status: response.status,
                message: response.data
                    ?.message
            });
        }

        setUploads([]);
        setLoading(false)
    };

    /**
     * Handles error response from the upload request.
     * @param {Object} error - The error object.
     */
    const handleErrorResponse = (error) => {
        if (error.response
            ?.data.errors
                ?.length > 0 && error.response.data.errors[0] !== true) {

            const newAlerts = error
                .response
                .data
                .errors
                .map(err => ({status: error.status, message: err?.msg}));

            setAlert({
                ...alert,
                alerts: newAlerts
            });
        } else {
            setAlert({
                ...alert,
                status: error.status,
                message: error.response.data.message
            });
        }

        setLoading(false)
    };

    return (
        <div
            className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-white px-3 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                onClick={cancel}>
                Cancel
            </button>
            <button
                type="button"
                onClick={e => handleUploadRequest(e)}
                className="inline-flex w-full justify-center rounded-md bg-ssg-600 px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-ssg-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ssg-600 sm:col-start-2 capitalize"
                disabled={loading}>
                Finish
            </button>
            <GeneralOverlay open={loading} setOpen={null}>
                <div className={'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'}>
                    <h2 className="text-base font-semibold leading-6 text-gray-900">Your data is processing</h2>
                    <div className="my-2">
                        <p className="text-sm text-gray-500">
                            Once your data has finished processing this window will close and you will have the ability to map and visulaize your data.
                        </p>
                    </div>
                    <FaSpinner className={'spin mx-auto text-4xl mt-6'} />
                </div>
            </GeneralOverlay>
        </div>
    )
}
