import {FaKeyboard} from "react-icons/fa";

export const ControlButtonHint = () => {
    return (
        <div className={'px-4 lg:px-8 pt-3'}>
            <div className="border border-gray-300 p-2 rounded inline-flex items-center bg-gray-200 text-xs italic">
                <FaKeyboard className="mr-2" />
                <span>Press <strong className="font-semibold">Command + O</strong> to open & close controls.</span>
            </div>
        </div>
    )
}
