import {Fragment, useContext} from 'react';
import {Route, Routes} from 'react-router-dom';
import './tailwind.css';

// import any other pages
import {ComingSoon} from './pages/ComingSoon';
import {AuthContext} from './context/AuthContext';
import {Dashboard} from './pages/Dashboard';
import {Header} from './components/Dashboard/Header';
import {Sidebar} from './components/Dashboard/Sidebar';
import {Guest} from './pages/Guest';
import {Transition} from '@headlessui/react';
import { MenuContext } from './context/MenuContext';
import { UploadManager } from './pages/UploadManager';
import {MappingPanel} from "./components/Maps/MappingPanel";
import {Visualize} from "./pages/Visualize";
import {VisualizeFile} from "./components/Visualization/VisualizeFile";
import {VisualizationContainer} from "./components/Visualization/VisualizationContainer";
import {Map} from "./pages/Map";

function App() {
    const {menuOpen} = useContext(MenuContext)
    const {authenticated} = useContext(AuthContext)

    return (
        <Fragment>
            {authenticated
                ? <div className="flex h-screen bg-gray-100 overflow-hidden">
                        <Transition
                            show={menuOpen}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            className="flex lg:flex-shrink-0">
                                <Sidebar/>
                        </Transition>


                        <div className="flex flex-col w-0 flex-1 overflow-hidden">
                            <Header />
                            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
                                <Routes>
                                    <Route path="/dashboard" element={< Dashboard />}/>
                                    <Route path="/sources" element={< UploadManager />}/>
                                    <Route
                                        path="/sources/:fileId/map"
                                        element={<MappingPanel />}/>
                                    <Route
                                        path="/rationalize"
                                        element={< ComingSoon message = "Data rationalization coming soon." />}/>
                                    <Route
                                        path="/visualize"
                                        element={<Visualize />}/>
                                    <Route
                                        path="/map"
                                        element={<Map />}/>
                                    <Route
                                        path="/map/:fileId"
                                        element={<MappingPanel />}/>
                                    <Route
                                        path="/visualize/:fileId"
                                        element={<VisualizationContainer />}/>
                                    <Route
                                        path="/connect"
                                        element={< ComingSoon message = "Data connections coming soon." />}/>

                                </Routes>
                            </main>
                        </div>
                    </div>
                : <Guest/>}
        </Fragment>
    );
}

export default App;
