import {Fragment, useContext} from 'react';
import {NumericStats} from '../components/NumericStats';
import Chart from '../components/chart';
import Staff from '../components/table-card';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import {useQuery} from '@tanstack/react-query';

const getFileStats = async ({queryKey}) => {
    const res = await axios.get('/api/v1/files/stats', {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${queryKey[1]}`
        }
    })

    return res.data.data
}

export const Dashboard = () => {
    const {authenticated} = useContext(AuthContext)
    const {status, data} = useQuery(['getFileStats', authenticated], getFileStats)

     return (
        <Fragment>
            {status === 'success' ? <NumericStats stats={data}/> : null}
            <Chart/>
            <Staff/>
        </Fragment>
    );
}
