import {Dialog} from "@headlessui/react";
import {GeneralOverlay} from "../../Overlays/GeneralOverlay";
import {BsBarChart} from "react-icons/bs";
import {LiaChartAreaSolid} from "react-icons/lia";
import {TbChartBubble} from "react-icons/tb";
import {BiDoughnutChart, BiPieChartAlt2, BiLineChart, BiRadar} from "react-icons/bi";
import {PiChartPolarThin, PiChartScatterThin} from "react-icons/pi";
import {useContext, useEffect, useState} from "react";
import {VisualizationContext} from "../../../context/VisualizationContext";
import {VisualizationStepButtons} from "../VisualizationStepButtons";



export const ChartSelector = () => {
    const {availableCharts, setChartType, setStep, showChartSelection,
        setShowChartSelection} = useContext(VisualizationContext);

    const handleTypeSelectionClick = type => {
        setChartType(type)
        setStep(prevStep => prevStep + 1)
    }
    return (
        <GeneralOverlay open={showChartSelection} setOpen={setShowChartSelection}>
            <div>
                <Dialog.Panel className="relative transform overflow-hidden transition-all p-3 sm:p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-auto max-w-5xl">
                    <div className={'col-span-full text-left grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'}>
                        <div className={'col-span-1 bg-white rounded-lg border-none p-6'}>
                            <h2 className={'font-bold text-xl text-gray-600'}>Select A Chart Type</h2>
                            <p className={'text-sm text-gray-400 mt-2'}>Click on the type of chart you would like to visualize your dataset in. This can always be changed.</p>
                        </div>
                    </div>
                    {availableCharts.map(({Icon, type, description, isAvailable}) => {
                        return (
                            <div key={type} className={'bg-white shadow-lg rounded-lg px-4 pb-4 pt-5 hover:bg-gray-50 cursor-pointer relative'} onClick={_e => handleTypeSelectionClick(type)}>
                                <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-lg bg-gray-100">
                                    <Icon className="h-10 w-10 text-gray-600 font-thin" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 capitalize">
                                        {type}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {description}
                                        </p>
                                    </div>
                                </div>
                                {
                                    !isAvailable ?
                                        <div className={'absolute -top-1 -right-2 bg-gray-100 py-2 px-4 rounded-lg text-xs flex flex-row items-center gap-2'}>
                                            <p>Coming Soon</p>
                                            <span className={'h-3 w-3 rounded-full bg-ssg'}></span>
                                        </div>
                                        : null
                                }
                            </div>
                        )})}
                </Dialog.Panel>
                <div className={'px-3 sm:px-6'}>
                    <VisualizationStepButtons />
                </div>
            </div>

        </GeneralOverlay>
    )
}
