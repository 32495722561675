import {useEffect, useState} from "react";
import {UploadCard} from "../components/Uploads/UploadCard";
import {UploadedFiles} from "../components/Uploads/UploadedFiles";
import {UploadActionPanel} from "../components/Uploads/UploadActionPanel";
import {UploadLog} from "../components/Uploads/UploadLog/UploadLog";
import {UploadConfirmationControls} from "../components/Uploads/UploadConfirmationControls";

const defaultCurrentUploadState = {
    files: [],
    action: undefined,
    option: undefined,
    includesHeader: undefined,
    keepOriginal: false
}

export const UploadManager = () => {
    const [refreshTrigger, setRefreshTrigger] = useState(0)
    const [uploads,
        setUploads] = useState([]);
    const [currentUpload,
        setCurrentUpload] = useState(defaultCurrentUploadState);
    const [showActionPanel,
        setShowActionPanel] = useState(false)

    useEffect(() => {
        if (currentUpload.files.length > 0) {
            setShowActionPanel(true)
        }
    }, [currentUpload])

    const reset = () => {
        resetCurrentUpload()
        setUploads([])
    }

    const resetCurrentUpload = () => {
        setCurrentUpload(defaultCurrentUploadState)
    }

    const addCurrentToUploads = () => {
        resetCurrentUpload()
        setShowActionPanel(false);
        setUploads([
            ...uploads,
            currentUpload
        ])
    }

    /**
     * Updates the current file state with new file data.
     * @param {Array<File>} newFiles - The new set of files to update the state with.
     */
    const handleChangeUploads = (newFiles) => {
        resetCurrentUpload()
        setUploads(newFiles);
    }

    return (
        <div className='p-4 lg:p-8'>
            {uploads.length > 0
                ? <UploadedFiles uploads={uploads} setUploads={handleChangeUploads}/>
                : null}

            <UploadCard upload={currentUpload} setUploads={setCurrentUpload}/>

            {uploads.length > 0
                ? <UploadConfirmationControls
                        uploads={uploads}
                        setUploads={setUploads}
                        refreshTrigger={refreshTrigger}
                        setRefreshTrigger={setRefreshTrigger}
                        cancel={reset}/>
                : null}

            <UploadLog refreshTrigger={refreshTrigger} setRefreshTrigger={setRefreshTrigger}/>

             <UploadActionPanel
                    open={showActionPanel}
                    setOpen={setShowActionPanel}
                    currentUpload={currentUpload}
                    setCurrentUpload={setCurrentUpload}
                    reset={resetCurrentUpload}
                    confirm={addCurrentToUploads}/>
        </div>
    )
}
