import {Fragment, useContext, useEffect} from "react"
import {FaTimes} from 'react-icons/fa'
import {AlertContext} from "../../context/AlertContext"

export const MultiAlert = () => {
    const {alert, setAlert} = useContext(AlertContext)

    /**
     * This effect sets up a delay (of 5 seconds) after which an interval starts. 
     * Every second, the interval will pop an alert from the list of alerts until 
     * there are no more alerts left. Once all alerts have been removed, the interval is cleared.
     * 
     * If the component unmounts before the delay is completed, the timeout is cleared 
     * to avoid unwanted side effects.
     */
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const intervalId = setInterval(() => {
                setAlert(prevAlert => {
                    const updatedAlerts = [...prevAlert.alerts];
                    
                    if (updatedAlerts.length === 0) {
                        clearInterval(intervalId);
                        return prevAlert;
                    }
                    
                    updatedAlerts.pop();
                    return { ...prevAlert, alerts: updatedAlerts };
                });
            }, 1000);
        }, 5000);
        
        return () => {
            clearTimeout(timeoutId);
        };
    })

    /**
     * Removes a specific alert from the list of alerts.
     * 
     * @param {Object} newAlert - The alert object to be removed.
     */
    const removeAlert = (newAlert) => {
        const updatedAlerts = alert
            .alerts
            .filter(item => item !== newAlert);
        setAlert({
            ...alert,
            alerts: updatedAlerts
        })
    }

    return (
        <div className="fixed top-2 right-0 mx-4 sm:w-1/3 z-50">
            {alert.alerts.map(newAlert => {
                return (
                    <Fragment key={Math.random()}>
                        {newAlert.status >= 200 && newAlert.status <= 302
                            ? <div
                                    className=" bg-green-100 px-6 py-4 rounded-tl-md rounded-bl-md my-3 flex flex-row justify-between items-center">
                                    <p className="text-green-600 capitalize">{newAlert.message}</p>
                                    <FaTimes
                                        onClick={e => removeAlert(newAlert)}
                                        className="text-green-600 font-bold cursor-pointer text-2xl"/>
                                </div>
                            : <div
                                className="w-full bg-red-100 px-6 py-4 rounded-tl-md rounded-bl-md my-3 flex flex-row justify-between items-center">
                                <p className="text-red-600 capitalize">{newAlert.message}</p>
                                <FaTimes
                                    onClick={e => removeAlert(newAlert)}
                                    className="text-red-600 font-bold cursor-pointer text-2xl"/>
                            </div>}
                    </Fragment>

                )
            })
}
        </div>
    )
}
