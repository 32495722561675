import {useUploadAction} from "../../../hooks/useUploadAction";
import React, {useContext} from "react";
import axios from "axios";
import {AuthContext} from "../../../context/AuthContext";
import {AlertContext} from "../../../context/AlertContext";
import {ComingSoon} from "../../Badges/ComingSoon";
export const DownloadActionButton = ({styles, fileId}) => {
    const action = useUploadAction('download');
    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);

    if (!action) return null;

    /**
     * Download a single file
     *
     * @param {string} _id
     * @sets actingOn
     * @sets alert
     * @return {void}
     */
    const downloadFile = async (_id) => {
        try {
            const res = await axios.get(`/api/v1/files/${_id}/download`, {
                responseType: 'blob', // Important: set the response type to 'blob'
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authenticated}`
                }
            })

            initiateBrowserDownload(res)
        } catch (error) {
            console.log(error)
            setAlert({...alert, status: 400, message: 'Currently cannot download file.'})
        }
    }

    /**
     * Initiate browser download
     *
     * @param {AxiosResponse} res
     * @return {void}
     */
    const initiateBrowserDownload =  (res) => {
        const filename = getDownloadFilename(res)
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);

        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    /**
     * Get file name from response headers
     *
     * @param {object} res
     * @return {string}
     */
    const getDownloadFilename = (res) => {
        const contentDisposition = res.headers['content-disposition'];
        let filename = 'downloaded_file'; // default filename if not provided
        if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    }

    return (
        <button
            onClick={_e => downloadFile(fileId)}
            className={styles
                ? styles
                : "rounded-md bg-gray-200 py-4 text-xs md:text-sm font-semibold text-ssg-800 shadow" +
                "-sm hover:bg-ssg-500 hover:text-white capitalize relative"}>{action.name}
            <ComingSoon available={action.available} />
        </button>
    )
}
