import {useContext, useState} from "react"
import {Logo} from "../components/Logo"
import axios from 'axios'
import {AlertContext} from "../context/AlertContext"
import {Link} from "react-router-dom";

export const Register = () => {
    const {alert, setAlert} = useContext(AlertContext)
    const [registration,
        setRegistration] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: ''
    })

    /**
     * Handles the login form submission. It validates the email address,
     * attempts to log in using the provided credentials, and sets alert
     * messages or redirects based on the result.
     *
     * @param {Event} e - The event object from the form submission.
     */
    const handleRegisterSubmission = async(e) => {
        e.preventDefault();

        const valid = validate();

        if (valid) {
            try {
                const res = await axios.post('/api/v1/auth/register', registration, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })

                if (res.status === 200) {
                    setAlert({
                        ...alert,
                        status: 200,
                        message: 'Successfully registered, logging in.'
                    })
                    setRegistrationCookie(res.data.token)
                    setTimeout(() => {
                        redirectIfSuccessful()
                    }, 400);
                }
            } catch (error) {
                const response = error.response;

                if (response.data.hasOwnProperty('errors')) {
                    const errors = response
                        .data
                        .errors
                        .map(err => ({status: response.status, message: err.msg}))
                    setAlert({
                        ...alert,
                        alerts: errors
                    });
                } else {
                    setAlert({
                        ...alert,
                            status: response.data.status,
                            message: response.data.message
                    })
                }
            }

        } else {
            setAlert({
                ...alert,
                status: 400,
                message: 'Invalid credentials'
            })
        }
    }

    const validate = () => {
        if (isMissingValue()) {
            setAlert({
                ...alert,
                status: 400,
                message: 'All fields are required.'
            });
            return false;
        }

        if (registration.firstName.trim() === '') {
            setAlert({
                ...alert,
                status: 400,
                message: 'First name cannot be empty.'
            });
            return false;
        }

        if (!isValidEmail(registration.email)) {
            setAlert({
                ...alert,
                status: 400,
                message: 'Invalid email format.'
            });
            return false;
        }

        if (!passwordsMatch()) {
            setAlert({
                ...alert,
                status: 400,
                message: 'Passwords do not match.'
            });
            return false;
        }

        return true;
    }

    const isMissingValue = () => {
        for (let key in registration) {
            if (registration[key] === '') {
                return true;
            }
        }
        return false;
    }
    /**
     * Validates the provided email address against a common pattern.
     *
     * @param {string} email - The email address to validate.
     * @returns {boolean} True if the email address is valid, false otherwise.
     */
    const isValidEmail = (email) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }

    const passwordsMatch = () => {
        return registration.password === registration.password_confirmation;
    }

    /**
     * Redirects the user to the dashboard page if login was successful.
     * This function assumes that it will only be called upon successful login.
     */
    const redirectIfSuccessful = () => {
        window.location.href = '/dashboard'
    }

    /**
     * Sets an registrationentication cookie to indicate the user is registrationenticated.
     */
    const setRegistrationCookie = (token) => {
        document.cookie = `auth=${token}`;
    }

    return (
        <div className="flex min-h-full flex-1 bg-gray-900">
            <div
                className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <Logo/>
                        <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-50">
                            Register New Account
                        </h2>
                        <p className="text-sm font-light leading-9 text-gray-50">Already have an account?
                            <Link to={"/login"}>
                                <span className={'underline ml-2'}>Login Here</span>
                            </Link>
                        </p>
                    </div>

                    <div className="mt-10">
                        <div>
                            <form onSubmit={handleRegisterSubmission} className="space-y-6">
                                <div
                                    className="grid grid-flow-row sm:grid-cols-2 sm:space-x-3 space-y-6 sm:space-y-0">
                                    <div className="col-span-1">
                                        <label
                                            htmlFor="firstName"
                                            className="block text-sm font-medium leading-6 text-gray-400">
                                            First Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                onChange={e => setRegistration({
                                                ...registration,
                                                firstName: e.target.value
                                            })}
                                                autoComplete="firstName"
                                                placeholder="First Name"
                                                required
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6 p-3"/>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <label
                                            htmlFor="lastName"
                                            className="block text-sm font-medium leading-6 text-gray-400">
                                            Last Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="lastName"
                                                name="lastName"
                                                type="text"
                                                onChange={e => setRegistration({
                                                ...registration,
                                                [e.target.name]: e.target.value
                                            })}
                                                autoComplete="lastName"
                                                placeholder="Last Name"
                                                required
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6 p-3"/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-400">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="text"
                                            onChange={e => setRegistration({
                                            ...registration,
                                            [e.target.name]: e.target.value
                                        })}
                                            autoComplete="email"
                                            placeholder="Email Address"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-medium leading-6 text-gray-400">
                                        Phone
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="phone"
                                            name="phone"
                                            type="text"
                                            onChange={e => setRegistration({
                                            ...registration,
                                            [e.target.name]: e.target.value
                                        })}
                                            autoComplete="phone"
                                            placeholder="Phone"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-100 sm:text-sm sm:leading-6 p-3"/>
                                    </div>
                                </div>

                                <div>
                                    <label
                                        htmlFor="password"
                                        className="block text-sm font-medium leading-6 text-gray-400">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            onChange={e => setRegistration({
                                            ...registration,
                                            [e.target.name]: e.target.value
                                        })}
                                            autoComplete="current-password"
                                            placeholder="Password"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>
                                <div>
                                    <label
                                        htmlFor="password_confirmation"
                                        className="block text-sm font-medium leading-6 text-gray-400">
                                        Confirm Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password_confirmation"
                                            name="password_confirmation"
                                            type="password"
                                            onChange={e => setRegistration({
                                            ...registration,
                                            [e.target.name]: e.target.value
                                        })}
                                            autoComplete="current-password"
                                            placeholder="Confirm Password"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        onClick={handleRegisterSubmission}
                                        className="flex w-full justify-center rounded-md bg-ssg-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-ssg-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ssg-600">
                                        Register
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""/>
            </div>
        </div>
    )
}
