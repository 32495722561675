import {useUploadAction} from "../../../hooks/useUploadAction";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../context/AuthContext";
import {AlertContext} from "../../../context/AlertContext";
import {ComingSoon} from "../../Badges/ComingSoon";
import {FileMetadataOverlay} from "../../Files/FileMetadataOverlay";
import {useGetFile} from "../../../hooks/useFiles";

export const ViewMetaActionButton = ({styles, fileId}) => {

    const {authenticated} = useContext(AuthContext);
    const {alert, setAlert} = useContext(AlertContext);
    const { data, error, isLoading } = useGetFile(authenticated, fileId)
    const action = useUploadAction('view meta');
    const [view, setView] = useState(false)
    const [file, setFile] = useState(undefined)

    useEffect(() => {
        if (data) {
            setFile(data);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            setAlert({ ...alert, status: 500, message: 'Something went wrong. Cannot retrieve your uploads...' });
        }
    }, [error]);

    if (!action) return null;

    return (
        <Fragment>
            <button
                onClick={_e => setView(!view)}
                className={styles
                    ? styles
                    : "rounded-md bg-gray-200 py-4 text-xs md:text-sm font-semibold text-ssg-800 shadow" +
                    "-sm hover:bg-ssg-500 hover:text-white capitalize relative"}>{action.name}
                <ComingSoon available={action.available} />
            </button>

            {view ? <FileMetadataOverlay file={file} open={view} setOpen={setView} /> : null}
        </Fragment>

    )
}
