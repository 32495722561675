import {Fragment, useContext} from "react";
import {FaTimes} from "react-icons/fa";
import {NumericGauge} from "../../AnalyticsAndStats/NumericGauge";
import {BaseStatsGrid} from "./BaseStatsGrid";
import {VisualizationContext} from "../../../context/VisualizationContext";

const complexityDefinition = 'Complexity is a number that is used to determine the complexity of your data structure on a scale of 0-100, 0 being very simple, 100 being complex.';
export const StatsContainer = () => {
    const {
        file,
        hidden,
        setHidden,
    } = useContext(VisualizationContext);

    return (
        <Fragment>
            {hidden.length < 6 ?
                <div className={'col-span-full grid grid-cols-1 md:grid-cols-9 lg:grid-cols-12 gap-4'}>
                    {hidden.includes('Complexity') ? null :
                        <div className={'bg-white'}>
                            <div className={'flex flex-row items-center justify-between'}>
                                <label className={'font-bold'}>Complexity</label>
                                <FaTimes onClick={_e => setHidden([...hidden, 'Complexity'])} className={'cursor-pointer'}/>
                            </div>
                            <NumericGauge numericValue={file?.metadata?.complexityScore ?? null} label={'Complexity'} info={complexityDefinition}/>
                        </div>}
                    <BaseStatsGrid />
                </div>
                : null}
        </Fragment>
    )
}
